<template>
  <va-action-button
    :item="item"
    @click="showPoints"
    :hide-label="icon"
    :label="$t('users.show_points')"
    icon="mdi-bitcoin"
    color="success"
    text
  ></va-action-button>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "UserPointsButton",
  props: {
    item: Object,
    icon: Boolean,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    async showPoints() {
      try {
        const transactions = await this.$admin.bankProvider.getPoints(this.item);
        this.$emit('answer', transactions.data.data)
        // show modal
      } catch ({ response }) {
        this.$snackbar.error(response.data.message);
      }
    },
  },
};
</script>
