const SET_TARGET = "setTarget";
const SET_TARGET_USER = "setTargetUser";
const UPDATE_TARGET_USER = "updateTargetUser";

export default (httpClient, params = {}) => {
  params = {
    routes: {
      create_target: "add-target-challenge",
      add_target_user: "add-target-to-user",
      update_target_user: "update-target-to-user",
    },
    getCredentials: () => {
      return {
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        client_secret: process.env.VUE_APP_AUTH0_CLIENT_SECRET,
      };
    },
    ...params,
  };

  let {
    routes,
    getCredentials,
  } = params;

  return {
    [SET_TARGET]: async ({ challenge_id, targets }) => {
      return await httpClient.post(routes.create_target, { ...getCredentials(), challenge_id, targets },
        {headers: { 'Content-Type': 'application/json' }});
    },
    [SET_TARGET_USER]: async ({ user_id, targets }) => {
      return await httpClient.post(routes.add_target_user, { ...getCredentials(), user_id, targets },
        {headers: { 'Content-Type': 'application/json' }});
    },
    [UPDATE_TARGET_USER]: async ({ id, enabled }) => {
      return await httpClient.put(routes.update_target_user, { ...getCredentials(), id, enabled },
        {headers: { 'Content-Type': 'application/json' }});
    }
  };
};
