<template>
  <va-show :item="item">
    <va-field source="name" />
    <va-field source="sku" />
    <va-field source="quantity" />
    <va-field source="price" />
  </va-show>
</template>

<script>
export default {
  props: ["item"],
};
</script>
