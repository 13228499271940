<template>
  <v-card>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="questionsByChallenge"
          :loading="load"
          loading-text="Espera por favor ..."
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="font-weight-bold">Preguntas del reto</v-toolbar-title>
            <v-spacer />
            <v-dialog v-model="openRewardChallenge" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="allQuestions"
                >
                  Añadir Preguntas
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="font-weight-bold">Relacionar pregunta con reto</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                            v-model="listQuestions"
                            :loading="loadQuestionsInField"
                            :items="questions"
                            multiple
                            item-text="question"
                            item-value="id"
                            label="Preguntas del cliente"
                            chips
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="error"
                      text
                      @click="openRewardChallenge = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      color="success"
                      text
                      @click="addQuestionToChallenge"
                      :loading="saveRelation"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="enableQuestion(item)"
              :color="[null, false].includes(item.enabled) ? 'red' : 'green'"
          >
            {{ [null, false].includes(item.enabled) ? 'mdi-close' : 'mdi-check' }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="this.is-modal">
      <v-btn text color="primary" @click="cancel">Cerrar</v-btn>
    </v-card-actions>
    <snack-alert :show-snack="message.show" :message="message" @closeSnack="statusSnack" />
  </v-card>
</template>

<script>
import SnackAlert from "@/components/common/SnackAlert";

export default {
  name: "QuestionsList",
  props: [ 'challengeId', 'is-modal'],
  components: {
    SnackAlert
  },
  data: () => {
    return {
      show: false,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Pregunta', value: 'question.question' },
        { text: 'Respuestas', value: 'question.answers'},
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      questionsByChallenge: [],
      load: false,
      questions: [],
      openRewardChallenge: false,
      saveRelation: false,
      message: {
        show: false,
        timeout: 2000,
        text: 'Reward create successfully',
        type: 'success'
      },
      loadQuestionsInField: false,
      listQuestions: []
    }
  },
  mounted() {
    this.questionsByChallengeAPI();
  },
  methods: {
    cancel () {
      this.$emit('closeModalQuestion', { status: false })
      this.questionsByChallenge = []
      this.load = false
    },

    enableQuestion (item) {
      this.$admin.http.put(`/challenge-question/${item.id}/enabled`, { enabled: !item.enabled }, {headers: { 'Content-Type': 'application/json' }}).then(async () => {
        this.message.show = true
        this.message.type = 'success'
        this.message.text = 'Reward update successfully'
        this.saveRelation = false
        await this.questionsByChallengeAPI()
      }).catch((error) => {
        this.message.show = true
        this.message.text = error.toString()
        this.message.type = 'error'
        this.saveRelation = false
      })
    },
    async addQuestionToChallenge () {
      this.saveRelation = true
      const responses = []
      for (const question of this.listQuestions) {
        const { data } = await this.$admin.http.post(`/challenge-question/`, {
          question: question,
          challenge: this.challengeId,
          enabled: true
        }, {headers: { 'Content-Type': 'application/json' }})
        if (Object.values(data).includes('error')) {
          responses.push(question)
        }
      }

      this.message.show = true
      if (responses.length > 0) {
        this.message.text = "Error in questions" + responses.length
        this.message.type = 'error'
      } else {
        this.openRewardChallenge = false
        this.message.type = 'success'
        this.message.text = 'Reward update successfully'
        await this.questionsByChallengeAPI()
      }
      this.saveRelation = false

    },
    async questionsByChallengeAPI () {
      this.load = true
      const { data } = await this.$admin.http.get(`/challenge-question/${this.challengeId}/challenge`)
      this.questionsByChallenge = data
      this.load = false
    },
    statusSnack (value) {
      this.message.show = value.status
    },
    async allQuestions () {
      this.loadQuestionsInField = true
      const { data } =  await this.$admin.http.get(`/questions/`)
      this.questions = data
      this.loadQuestionsInField = false
    },
  },
}
</script>

<style scoped>

</style>