<template>
  <div>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list ref="list">
        <va-data-table :fields="fields" />
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        { source: "name", sortable: true },
        { source: "sku" },
        { source: "quantity" },
        { source: "price" },
      ],
    };
  },
};
</script>
