<template>
  <div>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list ref="list">
        <va-data-table :fields="fields" />
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  async beforeRouteUpdate(to, from) {
    this.resource = this.$admin.resources.challenges;
    console.log(to);
    console.log(from);
    // react to route changes...
    //this.userData = await fetchUser(to.params.id)
  },
  data() {
    return {
      fields: [
        { source: "description", sortable: true },
        { source: "points_to_win" },
        { source: "target.name" },
        { source: "created_at", type: "date" },
      ],
    };
  },
};
</script>
