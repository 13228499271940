<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col lg="8">
        <va-text-input source="longitude" />
        <va-text-input source="latitude" />
        <va-text-input source="name" />
        <va-text-input source="address" />
        <va-save-button></va-save-button>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
