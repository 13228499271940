<template>
  <va-create-layout :title="title">
    <questions-form :item="item" />
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
