<template>
  <va-show :item="item">
    <va-field source="name" />
    <va-field source="description" />
    <va-field source="characteristics" />
    <va-field source="images" />
  </va-show>
</template>

<script>
export default {
  props: ["item"],
};
</script>
