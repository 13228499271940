export default [
  {
    name: "pqrs",
    icon: "mdi-post",
    api: "/pqrs",
    permissions: ["superadm", "worker"],
  },
  {
    name: "faqs",
    icon: "mdi-post",
    api: "/faqs",
    permissions: ["superadm", "worker"],
  },
  {
    name: "users",
    icon: "mdi-account",
    permissions: ["superadm"],
  },
  {
    name: "clients",
    icon: "mdi-account-tie",
    permissions: ["superadm", "client"],
  },
  {
    name: "challenges",
    icon: "mdi-incognito",

    routes: ["list", "create", "create", "edit", "show", "subchallenges"],
    permissions: ["superadm", "worker", "client"],
  },
  {
    name: "targets",
    icon: "mdi-store",
    permissions: ["superadm", "worker", "client"],
  },
  {
    name: "questions",
    icon: "mdi-frequently-asked-questions",
    permissions: ["superadm", "client"]
  },
  {
    name: "segmentations",
    icon: "filter_alt",
    permissions: ["superadm", "client"],
  },
  {
    name: "products",
    provider: "marketProvider",
    icon: "mdi-shopping",
    permissions: ["superadm", "client"],
  },
  {
    name: "bank",
    provider: "bankProvider",
    icon: "mdi-shopping",
    permissions: ["superadm", "client"],
  },
  {
    name: "catalogs",
    provider: "marketProvider",
    icon: "mdi-shopping",
    permissions: ["superadm", "client"],
  },
  {
    name: "categories",
    provider: "marketProvider",
    icon: "mdi-shopping",
    permissions: ["superadm", "client"],
  },
  {
    name: "orders",
    provider: "marketProvider",
    icon: "mdi-shopping",
    permissions: ["superadm", "client"],
    label: "orders",
  },
  {
    name: "notifications",
    icon: "mdi-bell",
    permissions: ["superadm", "client"],
    label: "Notificaciones",
  },
];
