<template>
  <va-edit-layout :title="title">
    <notifications-form :item="item" :id="id" :permissions="permissions" />
  </va-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item", "permissions"],
};
</script>
