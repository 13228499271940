<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <va-text-input source="name" label="Pregunta de segmentación" />
    <va-array-input source="options" label="opciones" v-slot="props">
      <v-row>
        <v-col sm="12">
          <va-text-input v-bind="props" source="option" label="Opción"/>
        </v-col>
      </v-row>
    </va-array-input>
    <va-autocomplete-input source="client" model="client" label="Cliente"
                           reference="users" item-value="id" itemText="name" :filter="{ roles: 'client' }"/>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item"],
};
</script>
