<template>
  <va-edit-layout :title="title">
    <products-form :item="item" :id="id" />
  </va-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
