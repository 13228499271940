<template>
  <div>
    <va-form :id="id" v-model="formModel" :item="item">
      <v-row justify="center">
        <v-col lg="8">
          <va-text-input source="name"/>
          <va-text-input source="email" type="email"/>
          <va-text-input :label="this.client? 'NIT': 'Documento'" source="document"/>
          <va-select-input v-show="!this.client" :choices="docTypes" source="documentType"/>
          <va-select-input v-show="!this.client" :choices="roles" source="roles"/>
          <va-date-field source="birthday"/>
          <va-select-input v-show="!this.client" :choices="genders" source="gender"/>
          <va-text-input source="phone"/>
          <!--va-password-input source="password" />
          <va-password-input source="password_confirmation" /-->
          <v-col lg="8">
            <v-row class="justify-space-between">
              <va-save-button v-if="!this.client"></va-save-button>
              <v-btn v-else :loading="this.loading" color="primary " @click="createUser">Guardar cliente</v-btn>
              <v-btn v-if="this.client" @click="$emit('closeModal')" color="error">Cerrar</v-btn>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
    </va-form>
    <snack-alert :message="message" :show-snack="message.show" @closeSnack="statusSnack"/>
  </div>
</template>

<script>
import SnackAlert from "@/components/common/SnackAlert.vue";

export default {
  components: {
    SnackAlert
  },
  props: ["id", "title", "item", "client", "parentId"],
  data() {
    return {
      loading: false,
      message: {
        show: false,
        timeout: 2000,
        text: 'Challenge created successfully',
        type: 'success'
      },
      genders: [
        {value: "masculino", text: "Masculino"},
        {value: "femenino", text: "Femenino"},
        {value: "otro", text: "Otro"},
      ],
      docTypes: [
        {value: "cc", text: "Cédula"},
        {value: "ce", text: "Carnet extranjería"},
        {value: "nit", text: "NIT"},
        {value: "Pasaporte", text: "PASAPORTE"},
      ],
      roles: [
        {value: "superadm", text: "Administrador"},
        {value: "worker", text: "Empleado"},
        {value: "client", text: "Cliente"},
        {value: "manager", text: "Coordinador"},
        {value: "user", text: "Usuario"},
      ],
      formModel: {}
    };

  },
  methods: {

    async createUser() {
      let formData = new FormData();

      for (const key in this.formModel) {
        formData.append(key, this.formModel[key])
      }

      this.loading = true;
      try {
        await this.$admin.http.post(`/users`, formData, {headers: {'Content-Type': 'multipart/form-data'}})

        this.message.show = true
        this.message.text = 'Creado con exito'
        this.message.type = 'success'
        this.stepper = 4
      } catch (e) {
        this.message.show = true
        this.message.text = e.toString()
        this.message.type = 'error'
      }
      this.loading = false;
      this.$emit('closeModal')
    },
    statusSnack(value) {
      this.message.show = value.status
    },
  },
  beforeMount() {
    if (this.client) {
      this.formModel.documentType = 'nit'
      this.formModel.roles = 'client'
      this.formModel.gender = 'otro'

    }
  }
};
</script>
