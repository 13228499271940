<template>
  <div>
    <base-material-card :icon="resource.icon" :title="title" >
      <va-list ref="list" >
        <va-data-table :fields="fields" >
          <template v-slot:[`field.user_id`]="{ value }">
            <v-chip-group column>
              <va-reference-field
                  resource="user_id"
                  reference="users"
                  item-value="user.name"
                  color="primary"
                  small
                  chip
                  fetch
                  :item="value"
              >
              </va-reference-field>
              {{ value }}
            </v-chip-group>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        { source: "total", sortable: true },
        { source: "user_id" },
        { source: "tracking_number" },
        { source: "state" },
      ],
    };
  },
};
</script>
