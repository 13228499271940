<template>
  <div>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list ref="list">
        <va-data-table :fields="fields">
          <template v-slot:[`item.actions`]="{ resource, item }">
            <impersonate-button :resource="resource" :item="item" icon />
            <user-points-button :resource="resource" :item="item" icon @answer="showModal"/>
            <challenges-button :resource="resource" :item="item" icon />
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
    <v-row justify="center">
      <v-dialog
          v-model="pointsModal"
          scrollable
          max-width="600px"
      >
        <v-card>
          <v-card-title>Lista de transacciones</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 500px;" v-if="transactionsList.length">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Descripción
                  </th>
                  <th class="text-left">
                    Monto
                  </th>
                  <th class="text-left">
                    Monto Final
                  </th>
                  <th class="text-left">
                    Fecha
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in transactionsList" :key="item.id">
                  <td>{{ item.details }}</td>
                  <td>{{ item.transaction }}</td>
                  <td>{{ item.total }}</td>
                  <td>{{ new Date(item.created_at).toLocaleString('sv-SE') }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="pointsModal = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ImpersonateButton from "@/components/buttons/ImpersonateButton";
import UserPointsButton from "@/components/buttons/UserPointsButton";
import ChallengesButton from "../../components/buttons/ChallengesButton";

export default {
  components: {
    ChallengesButton,
    ImpersonateButton,
    UserPointsButton
  },
  props: ["resource", "title", "item"],
  data() {
    return {
      fields: [
        { source: "name", sortable: true },
        { source: "email", type: "email" },
        { source: "roles", type: "array" },
      ],
      pointsModal: false,
      transactionsList: {},
      categoriesModal: false,
      categoriesList: [],
      selectCategory: 0,
      family: ['Bebidas', 'Comida', 'Servicio', 'Dulces', 'Imagenes'],
      load: false,
      category: {
        "name": "",
        "ia_class_index": 0,
        "ia_class_name": "",
        "family": ""
      },
      userId: ''
    };
  },
  methods: {}
};
</script>
