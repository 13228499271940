const CREATE_NOTIFICATION = "createNotification";
const ACTIVE_NOTIFICATION = "updateTargetUser";

export default (httpClient, params = {}) => {
    params = {
        routes: {
            active_notification: "active-notification",
            create_notification: "create-notification"

        },
        getCredentials: () => {
            return {
                client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
                client_secret: process.env.VUE_APP_AUTH0_CLIENT_SECRET,
            };
        },
        ...params,
    };

    let {
        routes,
        getCredentials,
    } = params;

    return {
        [CREATE_NOTIFICATION]: async ({ user_id, targets }) => {
            return await httpClient.post(routes.create_notification, { ...getCredentials(), user_id, targets },
                {headers: { 'Content-Type': 'application/json' }});
        },
        [ACTIVE_NOTIFICATION]: async ({ id, enabled }) => {
            return await httpClient.post(routes.active_notification, { ...getCredentials(), id, enabled },
                {headers: { 'Content-Type': 'application/json' }});
        }
    };
};
