/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  {
    icon: "mdi-view-dashboard",
    text: i18n.t("menu.dashboard"),
    link: "/",
  },
  { divider: true },
  admin.getResourceLink("users"),
  admin.getResourceLink("clients"),
  admin.getResourceLink("challenges"),
  admin.getResourceLink("segmentations"),
  admin.getResourceLink("faqs"),
  admin.getResourceLink("pqrs"),
  admin.getResourceLink("targets"),
  admin.getResourceLink("questions"),
  { divider: true },
  admin.getResourceLink("products"),
  admin.getResourceLink("catalogs"),
  admin.getResourceLink("orders"),
  { divider: true },
  admin.getResourceLink("notifications"),
  { divider: true },
  admin.getResourceLink("label"),
];
