<template>
  <div>
    <div class="under-options">
      <v-btn
          @click="savedShapes(false)"
          color="success"
          class="mr-1"
          v-if="image && image.getShapeList().length"
      >Guardar Foto Etiquetada</v-btn>
      <!-- MODAL'S BUTTON -->
      <v-btn
          @click="openModal"
          class="mx-1"
          color="info"
          v-if="executionData.challenge_type"
          :disabled="executionData.challenge_type === 'photo_ocr'"
      >
        Agregar figura
      </v-btn>
      <v-btn
          @click="copyShapes"
          class="mx-1"
          color="secondary"
          outlined
          v-if="image && image.getShapeList().length"
      >
        Copiar etiquetas
      </v-btn>
      <v-btn
          @click="pasteShapes"
          color="primary"
          class="mx-1"
          outlined
          v-if="image && clipbrd"
      >
        Pegar etiquetas
      </v-btn>
      <v-btn
          @click="savedShapes(true)"
          class="mx-1"
          color="warning"
          outlined
          v-if="image && image.getShapeList().length"
      >
        Guardar y avanzar consecutivo
      </v-btn>
    </div>
    <div class="div-container">
      <div ref="test" class="div-container__label-img">
      </div>
      <div class="div-container__actions">
        <!-- BUTTONS FOR ADDING SHAPES IN IMAGE -->
        <h3>Lista de figuras agregadas</h3>
        <v-item-group class="div-container__actions-shape--list" v-if="image">
          <v-btn
              @click="addShape(label.registerID)"
              v-for="label in image.getRegisterMap()"
              v-bind:key="label.registerID"
              small dark
          >{{ label.tag }}</v-btn>
        </v-item-group>

        <!-- SHAPE'S CARDS -->
        <h3 v-if="image && image.getShapeList().length">Formas en la imagen</h3>
        <v-item-group v-if="image" class="group-cards">
          <v-card
              v-for="shape in image.getShapeList()"
              v-bind:key="shape.id"
              width="320"
              class="group-cards__item"
          >
            <v-card-title>{{ shape.tagContent }} - {{ shape.id }}</v-card-title>
            <div class="group-cards__item-actions">
              <v-btn
                  @click="actionsShapes(shape, 'remove')"
                  color="error"
                  class="btn-cards"
                  outlined
                  small
              >Eliminar</v-btn>
            </div>
            <v-divider></v-divider>
          </v-card>
        </v-item-group>
      </div>
    </div>

    <!-- MODAL FOR ADDING NEW SHAPE -->
    <v-row justify="center">
      <v-dialog v-model="modal" width="700">
        <v-card>
          <v-card-title>Agregar una figura</v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-text-field @input="convertToSlug" v-model="name" label="Nombre:" />
          </v-card-text>
          <v-divider></v-divider>

          <div class="modal-add-shape__footer">
            <v-btn @click="saved" color="success">Agregar</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import LabelImg from "label-img";

export default {
  computed: {},
  data() {
    return {
      executionData: {},
      image: null,
      modal: false,
      id: "",
      name: "",
      tab: null,
      color: "",
      value: 1,
      clipbrd: null,
    };
  },
  mounted() {
    /* CREATE INSTANCE OF LABEL IMAGE */
    this.image = new LabelImg(this.$refs.test, {
      width: 800,
      height: 600,
      bgColor: `#000`,
      imagePlacement: "default",
    });

    this.clipbrd = JSON.parse(localStorage.getItem('clipboard'));
    this.getExecutionData().then(({data})=>{
      this.executionData = data;

      const photoCategories = Object.entries(
          data.label_categories
      ).map(([key, value]) => ({ key, value }));

      photoCategories.sort((a,b)=> {
        const A = a.key.toLowerCase()
        const B = b.key.toLowerCase()
        if(A < B)
          return -1
        if(A > B)
          return 1
        return 0
      })
      /* REGISTER SHAPES ACCORDING WITH PHOTO CATEGORIES */
      photoCategories.forEach((item) => {
        this.image.register(item.key, {
          type: "Rect",
          tag: item.value,
        });
      });

      if (data.metadata) {
        /* IF CLIENT SAVED SHAPES */
        this.image.load(data.photo).then(this.loadShapes(data.metadata.tagging));
      }
      else this.image.load(data.photo);
    });
  },
  methods: {
    async getExecutionData() {
      try {
        return await this.$admin.http.get(
            `/executions/${this.$route.params.id}/light`
        );
      } catch ({ response }) {
        this.$admin.toast.error('Error al cargar la ejecución '+response);
      }
    },
    loadShapes(shapes) {
      shapes.forEach((item) => {
        item.positions.forEach((position) => {
          const shape = this.image.createShape(item.key, {
            /* CONVERT STRING TO INT POSITIONS' ITEMS */
            positions: position.map((itemPosition) =>
                itemPosition.map((formatPosition) => Number(formatPosition))
            ),
          });
          this.image.addShape(shape);
        });
      })
    },
    copyShapes() {
      this.clipbrd = this.image.getShapeList().map((item) => ({
        key: item.registerID,
        name: item.tagContent,
        positions: [item.positions],
      }));
      localStorage.setItem('clipboard', JSON.stringify(this.clipbrd));
      this.$admin.toast.info('Guardado en portapapeles');
    },
    pasteShapes() {
      if(this.image.getShapeList().length){
        this.$admin.toast.error('Solo se puede pegar en imagen vacía');
        return false;
      }
      this.clipbrd = JSON.parse(localStorage.getItem('clipboard'));
      if(!this.clipbrd){
        this.$admin.toast.error('Portapapeles vacío');
      }
      else this.loadShapes(this.clipbrd);
    },
    savedShapes(consecutive = false) {
      const formatShapesInfo = this.image.getShapeList().map((item) => ({
        key: item.registerID,
        name: item.tagContent,
        positions: [item.positions],
      }));

      this.$admin.toast.info('Guardando...');
      try {
        this.$admin.dataProvider.updateLight("executions", {
          id: this.$route.params.id,
          data: {
            tagging: formatShapesInfo,
          },
        }).then(()=>{
          this.$admin.toast.success('Guardado correctamente, redirigiendo a siguiente foto');
          if(!consecutive) {
            this.$admin.http.get(
                `/executions/${this.$route.params.id}/next`
            ).then(({data}) => {
              this.$router.push({path: '/execution/' + data.id + '/label'}).then(() => window.location.reload());
            });
          }
          else {
            this.$router.push({path: '/execution/' + (parseInt(this.$route.params.id)+1) + '/label'}).then(() => window.location.reload());
          }
        });
      } catch ({ response }) {
        this.$admin.toast.error(response);
        // copiar etiquetas
      }
    },
    saved() {
      this.image.register(this.id, {
        tag: this.name,
        type: "Rect",
      });

      this.id = "";
      this.name = "";

      this.openModal();
    },
    convertToSlug(name) {
      this.id = name.toLowerCase().replace(/ /g, '_').replace(/[^\w-]+/g)
    },
    openModal() {
      this.modal = !this.modal;
    },
    addShape(shape) {
      this.image.label(shape);
    },
    actionsShapes(shape, type) {
      switch (type) {
        case "hidden":
          shape.isHidden() ? shape.show() : shape.hidden();
          break;

        case "disabled":
          shape.isDisabled() ? shape.normal() : shape.disabled();
          break;

        case "remove":
          this.image.remove(shape);
          break;

        case "tag":
          shape.tagShow();
          break;

        default:
          break;
      }

      this.image.render();
    },
  },
};
</script>

<style lang="sass">
.div-container
  display: flex
  justify-content: space-around

  &__actions
    display: flex
    flex-direction: column
    align-items: center
    width: 40rem

    &-shape
      &--add
        width: 10rem
        margin-bottom: 1rem
      &--list
        margin: 1rem 0
        button
          margin: 0.35rem

  .group-cards
    max-height: 20rem
    overflow-y: auto
    margin-bottom: 2rem

    &__item
      margin: 1rem

      &-actions
        display: flex
        justify-content: center
        margin-bottom: 1rem

        button
          width: 10rem
          &:nth-child(odd)
            margin-right: 1rem

.modal-add-shape__footer
  display: flex
  justify-content: center
  button
    width: 10rem
    margin: 1rem 0
.under-options
  position: relative
  margin-bottom: 1rem
</style>
