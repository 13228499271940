<template>
  <va-form :id="id" :item="item">

    <v-row justify="center">
      <v-col lg="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-text-input source="name" />
            <va-text-input source="description" />
            <va-array-input source="offers" v-slot="props">
              <v-row>
                <v-col sm="6">
                  <va-select-input
                    source="product"
                    v-bind="props"
                    label="Producto"
                    :choices="productList"
                    itemValue="id"
                    itemText="name"
                  />
                </v-col>
                <v-col sm="6">
                  <va-number-input v-bind="props" source="quantity" label="Cantidad"/>
                </v-col>
                <v-col sm="6">
                  <va-number-input label="Descuento" v-bind="props" source="discount"/>
                </v-col>
                <v-col sm="6">
                  <va-boolean-input v-bind="props" source="discount_is_percentage" label="Descuento es porcentaje"/>
                </v-col>
                <v-col sm="6">
                  <va-number-input v-bind="props" source="loyalty_price" label="Precio en Bondiies"/>
                </v-col>
                <v-col sm="6">
                  <va-date-input v-bind="props" format="short" source="expires_at" label="Fin de oferta"/>
                  <!--va-text-input
                    v-bind="props"
                    source="extra_characteristics"
                  /-->
                </v-col>
              </v-row>
            </va-array-input>
            <va-array-input source="characteristics" v-slot="props">
              <v-row>
                <v-col sm="6">
                  <va-text-input v-bind="props" source="property" />
                </v-col>
                <v-col sm="6">
                  <va-text-input v-bind="props" source="value" />
                </v-col>
              </v-row>
            </va-array-input>
          </v-card-text>
          <va-save-button />
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item", "productList"],
  data() {
    return {
      types: [
        { value: "color", text: "Color" },
        { value: "size", text: "Size" },
        { value: "gender", text: "Gender" },
        { value: "category", text: "Category" },
      ],
    };
  },
};
</script>
