<template>
  <div>

    <v-card>
      <v-card-title class="font-weight-bold">{{ edit !== null ? 'Editar' : 'Agregar' }} Notificación</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="notification.title"
                  label="Título"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="notification.message"
                  label="Mensaje"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="8">
              <v-select
                  v-model="notification.type"
                  :items="types"
                  item-text="text"
                  item-value="key"
                  label="Recurrencia"
                  @change='()=>{this.notification.configuration =
                  {
                        dayOfWeek: "*",
                        dayOfMonth: "*",
                        hour: "*",
                        minute: "*"
                  } }'
              />
            </v-col>
            <v-col v-if="['monthly', 'weekly'].includes(notification.type) " cols="12" sm="4">
              <v-select v-if="notification.type === 'monthly'" v-model="notification.configuration.dayOfMonth"
                        :items="days"
                        label="Selecciona un día"></v-select>

              <v-select v-if="notification.type === 'weekly'" v-model="notification.configuration.dayOfWeek"
                        :items="daysOfWeek"
                        label="Selecciona un día"></v-select>
            </v-col>
            <v-col v-if="notification.type === 'daily'" sm="2">
              <v-select v-model="notification.configuration.hour"
                        :items="hours"
                        label="Hora del dia"></v-select>
            </v-col>
            <v-col v-if="notification.type === 'daily'" sm="2">
              <v-select v-model="notification.configuration.minute"
                        :items="minutes"
                        label="Minuto"></v-select>


            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-checkbox
                  v-model="notification.active"
                  label="Activa"
              />
            </v-col>
          </v-row>
          <v-divider/>
          <v-container>

            <v-row>

            </v-row>
          </v-container>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="edit === null" :loading="saveTo" color="success" text @click="rewardAction">
          Guardar
        </v-btn>
        <v-btn v-else :loading="saveTo" color="primary" text @click="updateReward">
          Actualizar
        </v-btn>
        <v-btn v-if="isModal" color="red" text @click="close">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-alert :message="message" :show-snack="message.show" @closeSnack="statusSnack"/>
  </div>
</template>

<script>
import SnackAlert from "@/components/common/SnackAlert.vue";

export default {
  name: "CreateNotificationForm",
  components: {SnackAlert},
  props: ["edit", "challengeId", "notificationEdit"],
  data: () => {
    return {
      types: [
        {key: 'once', text: 'Única'},
        {key: 'daily', text: 'Diaria'},
        {key: 'weekly', text: 'Semanal'},
        {key: 'monthly', text: 'Mensual'}
      ],
      selectedMonth: null,
      menu: false,
      date: null,
      selectedDate: null,
      daysOfWeek: [
        {key: 0, text: 'Domingo'},
        {key: 1, text: 'Lunes'},
        {key: 2, text: 'Martes'},
        {key: 3, text: 'Miércoles'},
        {key: 4, text: 'Jueves'},
        {key: 5, text: 'Viernes'},
        {key: 6, text: 'Sábado'},
      ],
      notification: {
        challenge_id: null,
        title: '',
        message: '',
        type: 'daily',
        configuration: {
          dayOfWeek: "*",
          dayOfMonth: "*",
          hour: "*",
          minute: "*"

        },
        is_static: true,
        active: false
      },
      message: {
        show: false,
        timeout: 2000,
        text: 'Reward create successfully',
        type: 'success'
      },
      saveTo: false
    }
  },
  computed: {
    days() {

      const days = [];
      for (let i = 1; i <= 31; i++) {
        days.push({key: i.toString(), text: i.toString()});
      }

      console.log(days)
      return days;

    },
    hours() {

      const hours = [];
      for (let i = 0; i <= 23; i++) {
        hours.push({key: i.toString(), text: i.toString()});
      }

      console.log(hours)
      return hours;

    },
    minutes() {

      const minutes = [];
      for (let i = 0; i <= 59; i++) {
        minutes.push({key: i.toString(), text: i.toString()});
      }

      console.log(minutes)
      return minutes;

    },
  },

  methods: {
    statusSnack(value) {
      this.message.show = value.status
    },
    close() {
      this.$emit('cancel', {modal: 'create', status: false})
    },
    rewardAction() {
      this.saveTo = true
      this.notification.challenge_id = this.challengeId
      this.$admin.httpNotifications.post(`/create-notification`, this.notification, {headers: {'Content-Type': 'application/json'}}).then(() => {
        this.$emit('cancel', {modal: 'create', status: false}) // ??
        this.message.show = true
        this.message.type = 'success'
        this.message.text = 'Notificación creada correctamente'
        this.saveTo = false
      }).catch((error) => {
        this.message.show = true
        this.message.text = error.toString()
        this.message.type = 'error'
        this.saveTo = false
      })
    },

    updateReward() {
      this.saveTo = true
      this.notification.client = this.client
      this.$admin.notificationsProvider.put(`/update-notification/${this.notification.id}`, this.notification, {headers: {'Content-Type': 'application/json'}}).then(() => {
        this.$emit('cancel', {modal: 'update', status: false})
        this.message.show = true
        this.message.text = 'Reward update :D'
        this.message.type = 'primary'
        this.saveTo = false
      }).catch((error) => {
        this.message.show = true
        this.message.text = error.toString()
        this.message.type = 'error'
        this.saveTo = false
      })
    }
  },
  mounted() {
    if (this.edit !== null) {
      this.notification = this.rewardEdit
    }
  }

}
</script>

<style scoped>

</style>