<template>
  <va-show :item="item">
    <va-field source="total" />
    <va-field source="user_id" />
    <va-field source="delivery" />
    <va-field source="tracking_number" />
    <va-field source="state" />
    <va-field source="items" />
  </va-show>
</template>

<script>
export default {
  props: ["item"],
};
</script>
