<template>
  <va-create-layout>
    <challenges-form :parentId="parentId" :parentClientId="clientId" />
  </va-create-layout>
</template>

<script>
export default {
  data(){
    return {
        parentId:this.$route.params.parentId,
        clientId:this.$route.params.clientId

    }
  },
  
  mounted() {
    console.log(this.parentId)
  }
};
</script>
