import {
  CHECK_AUTH,
  CHECK_ERROR,
  GET_EMAIL,
  GET_NAME,
  GET_PERMISSIONS,
  LOGIN,
  LOGOUT,
} from "vuetify-admin/src/providers/auth/actions";

export const GET_TOKEN = "getToken";

export default (httpClient, params = {}) => {
  params = {
    routes: {
      login: "api/auth/login",
      logout: "api/auth/logout",
      refresh: "api/auth/refresh",
      user: "api/auth/me",
    },
    storageKey: "jwt_token",
    getToken: (r) => JSON.stringify(r),
    getJsonToken: (r) => JSON.parse(r),
    getCredentials: ({ username, password }) => {
      return {
        email: username,
        password,
      };
    },
    getName: (u) => u.name,
    getEmail: (u) => u.email,
    getPermissions: (u) => u.roles,
    ...params,
  };

  let {
    routes,
    storageKey,
    getCredentials,
    getName,
    getEmail,
    getPermissions,
    getToken,
    getJsonToken,
  } = params;

  return {
    [LOGIN]: async ({ username, password }) => {
      let response = await httpClient.post(
        routes.login,
        getCredentials({ username, password })
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      localStorage.setItem(storageKey, getToken(response.data));
      return Promise.resolve();
    },
    [LOGOUT]: async () => {
      if (routes.logout) {
        await httpClient.post(routes.logout);
      }

      localStorage.removeItem(storageKey);
      return Promise.resolve();
    },
    [CHECK_AUTH]: async () => {
      /**
       * Refresh token
       */
      if (routes.refresh) {
        const credentials = getCredentials({});
        credentials.grant_type = "refresh_token";
        const { refresh_token } = JSON.parse(localStorage.getItem(storageKey));
        credentials.refresh_token = refresh_token;
        let response = await httpClient.post(routes.refresh, credentials);
        localStorage.setItem(storageKey, getToken(response.data));
      }

      /**
       * Get user infos
       */
      if (routes.user) {
        const credentials = getCredentials({});
        const { access_token } = JSON.parse(localStorage.getItem(storageKey));

        const tokenParts = access_token.split(".");
        const tokenPayload = atob(tokenParts[1]);
        const jwtPayload = JSON.parse(tokenPayload);
        credentials.user_id = jwtPayload.sub;
        let response = await httpClient.post(routes.user, credentials);

        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.data;
      }

      return localStorage.getItem(storageKey)
        ? Promise.resolve({
            data: true,
          })
        : Promise.reject();
    },
    [CHECK_ERROR]: ({ status }) => {
      if (status === 401 || status === 403) {
        localStorage.removeItem(storageKey);
        return Promise.reject();
      }
      return Promise.resolve();
    },
    [GET_NAME]: (user) => getName(user),
    [GET_EMAIL]: (user) => getEmail(user),
    [GET_PERMISSIONS]: (user) => getPermissions(user),
    [GET_TOKEN]: (jwt_token) => getJsonToken(jwt_token),
  };
};
