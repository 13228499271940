<template>
  <div>

    <v-card>
      <v-card-title class="font-weight-bold">{{ edit !== null ? 'Editar' : 'Agregar' }} Recompensa</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="reward.name"
                  label="Nombre"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="reward.description"
                  label="Descripción"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                  v-model="reward.type"
                  :items="types"
                  item-text="text"
                  item-value="key"
                  label="Tipo"
              />
            </v-col>
          </v-row>
          <v-divider/>
          <v-row dense>
            <v-col class="font-weight-bold" cols="12">Recompensa en:</v-col>
          </v-row>
          <v-divider/>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="reward.amount_loyalty"
                  label="Bondii Coins"
                  prefix="BC"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="reward.amount_money"
                  label="Moneda Local"
                  prefix="$"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="edit === null" :loading="saveTo" color="success" text @click="rewardAction">
          Guardar
        </v-btn>
        <v-btn v-else :loading="saveTo" color="primary" text @click="updateReward">
          Actualizar
        </v-btn>
        <v-btn v-if="isModal" color="red" text @click="close">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-alert :message="message" :show-snack="message.show" @closeSnack="statusSnack"/>
  </div>
</template>

<script>
import SnackAlert from "@/components/common/SnackAlert.vue";

export default {
  name: "CreateForm",
  components: {SnackAlert},
  props: ["edit", "client", "isModal", "rewardEdit"],
  data: () => {
    return {
      types: [
        {key: 'exhibition', text: 'Exhibición'},
        {key: 'capture', text: 'Captura'},
        {key: 'invoices', text: 'Facturas'}
      ],
      reward: {
        client: null,
        name: '',
        description: '',
        type: 'exhibition',
        amount_loyalty: 0,
        amount_money: 0
      },
      message: {
        show: false,
        timeout: 2000,
        text: 'Reward create successfully',
        type: 'success'
      },
      saveTo: false
    }
  },
  methods: {
    statusSnack(value) {
      this.message.show = value.status
    },
    close() {
      this.$emit('cancel', {modal: 'create', status: false})
    },
    rewardAction() {
      this.saveTo = true
      this.reward.client = this.client
      this.$admin.http.post(`/rewards/`, this.reward, {headers: {'Content-Type': 'application/json'}}).then(() => {
        this.$emit('cancel', {modal: 'create', status: false})
        this.message.show = true
        this.message.type = 'success'
        this.message.text = 'Reward create successfully'
        this.saveTo = false
      }).catch((error) => {
        this.message.show = true
        this.message.text = error.toString()
        this.message.type = 'error'
        this.saveTo = false
      })
    },
    updateReward() {
      this.saveTo = true
      this.reward.client = this.client
      this.$admin.http.put(`/rewards/${this.reward.id}`, this.reward, {headers: {'Content-Type': 'application/json'}}).then(() => {
        this.$emit('cancel', {modal: 'update', status: false})
        this.message.show = true
        this.message.text = 'Reward update :D'
        this.message.type = 'primary'
        this.saveTo = false
      }).catch((error) => {
        this.message.show = true
        this.message.text = error.toString()
        this.message.type = 'error'
        this.saveTo = false
      })
    }
  },
  mounted() {
    if(this.edit !== null){
      this.reward = this.rewardEdit
    }
  }

}
</script>

<style scoped>

</style>