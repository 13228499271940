<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col lg="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-text-input source="total" label="Precio Total" required />
            <va-select-input
              source="user_id"
              model="user_id"
              reference="users"
              itemValue="id"
              itemText="name"
              label="Comprador"
            />
            <v-row>
              <v-col sm="6">
                <va-text-input source="delivery.address" />
              </v-col>
              <v-col sm="6">
                <va-text-input source="delivery.city" />
              </v-col>
              <v-col sm="6">
                <va-text-input source="delivery.region" />
              </v-col>
              <v-col sm="6">
                <va-text-input source="delivery.zip" />
              </v-col>
              <v-col sm="12">
                <va-text-input source="delivery.details" />
              </v-col>
            </v-row>
            <va-text-input source="tracking_number" />
            <va-select-input
              :choices="states"
              source="state"
              required
              label="Estado"
            />
            <va-array-input label="Artículos" source="items" v-slot="props">
              <v-row>
                <v-col sm="6">
                  <va-select-input
                    source="product"
                    model="product"
                    reference="products"
                    itemValue="id"
                    itemText="name"
                    v-bind="props"
                    label="Producto"
                  />
                </v-col>
                <v-col sm="6">
                  <va-text-input
                    v-bind="props"
                    source="quantity"
                    label="Cantidad"
                  />
                </v-col>
                <v-col sm="6">
                  <va-number-input
                    v-bind="props"
                    source="total_price"
                    label="Precio total"
                  />
                </v-col>
                <v-col sm="6">
                  <va-select-input
                    :choices="states"
                    source="state"
                    required
                    v-bind="props"
                    label="Estado"
                  />
                </v-col>
                <v-col sm="12">
                  <template v-bind="props">
                    <va-array-input
                      :source="
                        props.parentSource +
                        '[' +
                        props.index +
                        '][characteristics]'
                      "
                      v-slot="props"
                      label="Detalles de la compra"
                    >
                      <v-row>
                        <v-col sm="6">
                          <va-text-input
                            v-bind="props"
                            source="property"
                            label="Propiedad"
                          />
                        </v-col>
                        <v-col sm="6">
                          <va-text-input
                            v-bind="props"
                            source="value"
                            label="Valor"
                          />
                        </v-col>
                      </v-row>
                    </va-array-input>
                  </template>
                </v-col>
              </v-row>
            </va-array-input>
            <!--va-array-input source="images" v-slot="props">
          <v-row>
            <v-col sm="12">
              <va-file-input
                v-bind="props"
                :label="$t('Image')"
                source="image"
                required
              />
            </v-col>
          </v-row>
        </va-array-input-->
          </v-card-text>
          <va-save-button />
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item", "permissions"],
  data() {
    return {
      states: [
        { value: "added", text: "En carrito" },
        { value: "ordered", text: "Comprado" },
        { value: "returned", text: "Devuelto" },
        { value: "issued", text: "Enviado" },
        { value: "sent", text: "Entregado" },
        { value: "in progress", text: "En progreso" },
        { value: "lost", text: "Perdido" },
        { value: "dipute", text: "En disputa" },
      ],
    };
  },
};
</script>
