<template>
    <div>

        <v-dialog v-model="createClientModal" max-width="60vw">
            <template>
                <v-card class="pb-5">
                    <v-card-title class="font-weight-bold text-h2 my-10">Crear Cliente</v-card-title>

                    <v-col>
                        <users-form :client="true" title="Crear cliente"
                                    @closeModal="createClientModal = false"></users-form>
                    </v-col>
                </v-card>
            </template>

        </v-dialog>
        <div class="form-container overflow-y-auto overflow-x-hidden">

            <va-form :id="id" ref="form" v-model="formModel" :disable-redirect="true" :item="item" @input="onChange"
                     @saved="onSaved">
                <div v-if="!is_sub_challenge" ref="subContainer1">
                    <v-card-title>Para empezar selecciona el cliente para el que se creará el reto</v-card-title>
                    <v-col sm="12">
                        <va-autocomplete-input item-value="id" itemText="company_name" label="Cliente"
                                               model="client" reference="clients" source="client"
                                               @change="getClient(item)"/>
                    </v-col>
                    <v-card-title>¿No está registrado?</v-card-title>
                    <v-col>
                        <v-btn color="primary" @click="createClientModal = true">crear cliente</v-btn>
                    </v-col>
                    <div>

                        <v-card-title>Presupuesto y metas</v-card-title>
                        <v-col sm="12">

                            <va-number-input :min="0" :step="1" label="Presupuesto del cliente (Dinero)"
                                             source="budget"/>
                            <v-row>
                                <v-col sm="6">
                                    <va-text-input label="Meta del cliente ( Descripción )" required
                                                   source="client_goal_description"/>
                                </v-col>
                                <v-col sm="6">
                                    <va-number-input :min="0" :step="1" label="Meta del cliente ( Cuantificación )"
                                                     required
                                                     source="client_goal"/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </div>
                    <v-col offset="10" sm="2">
                    </v-col>
                </div>

                <v-col v-if="!is_sub_challenge" sm="12">
                    <v-card-title class="mb-10">Segmentación de audiencia: añade y configura cada segmentación para
                        asignar el
                        reto
                    </v-card-title>
                    <va-array-input v-slot="props" label="Segmentar Audiencia" source="audience">
                        <v-row>
                            <v-col sm="12">
                                <va-select-input :choices="userProperties" label="Atributo" source="attribute"
                                                 v-bind="props"/>
                            </v-col>

                            <v-col sm="12">
                                <va-select-input :choices="comparisons" label="Comparación" source="comparer"
                                                 v-bind="props"/>
                            </v-col>
                            <v-col sm="12">
                                <template v-if="formModel.audience[props.index].attribute === 'age'">
                                    <va-number-input label="Valor" source="value" v-bind="props"/>
                                </template>
                                <template v-else-if="formModel.audience[props.index].attribute === 'company'">

                                    <va-select-input item-value="company_name" itemText="company_name" label="Cliente"
                                                     reference="clients" source="value" v-bind="props"/>
                                </template>
                                <template v-else-if="formModel.audience[props.index].attribute === 'transport'">

                                    <va-select-input :choices="transports" label="Transporte" source="value"
                                                     v-bind="props"/>


                                </template>
                                <template v-else-if="formModel.audience[props.index].attribute === 'gender'">

                                    <va-select-input :choices="genders" label="Genero" source="value" v-bind="props"/>


                                </template>
                                <template v-else>

                                    <va-text-input label="Valor" source="value" v-bind="props"/>

                                </template>
                            </v-col>
                            <v-col sm="12">
                                <va-select-input :choices="operators" label="Siguiente Condición" source="operator"
                                                 v-bind="props"/>
                            </v-col>
                        </v-row>
                    </va-array-input>
                </v-col>




                <v-card-title class="mb-10">Añade las cararacterísticas generales del reto</v-card-title>
                <v-col sm="12">
                    <va-text-input label="Nombre" required source="name"/>
                </v-col>
                <v-col sm="12" v-if="!is_sub_challenge">
                    <va-text-input :height="100" label="Descripción breve del objetivo del reto" multiline required
                                   source="description"/>
                </v-col>
                <v-col sm="12">
                    <va-select-input :choices="challengeTypes" :label="'Tipo'" required source="type"/>
                </v-col>
                <div v-if="!is_sub_challenge">
                    <v-col sm="12">
                        <va-array-input v-slot="props" label="Instrucciones del reto al hacerse por primera vez"
                                        source="instructions">

                            <va-text-input label="Escriba la instrucción" source="instruction" v-bind="props"/>

                        </va-array-input>
                    </v-col>
                    <v-col sm="12">
                        <va-array-input v-slot="props" label="Condiciones del reto (instrucciones adicionales)"
                                        source="conditions">
                            <v-row>
                                <v-col sm="12">
                                    <va-text-input label="Escriba la condición" source="condition" v-bind="props"/>
                                </v-col>
                            </v-row>
                        </va-array-input>
                    </v-col>

                </div>


                <v-col sm="6">
                    <va-boolean-input label="Ejecución colectiva" source="collective"/>
                </v-col>
                <v-col sm="12">
                    <va-number-input :min="0" :step="1" label="Cantidad de ejecuciones por misión" required
                                     source="amount_executions"/>
                </v-col>
                <v-col sm="12">
                    <va-number-input :min="0" :step="1" label="Dinero a entregar" source="points_to_win"/>
                </v-col>
                <v-col sm="12">
                    <va-number-input :min="0" :step="1" label="Bondiis a entregar" source="loyal_points_to_win"/>
                </v-col>
                <!--                <v-col sm="12">-->
                <!--                  <va-number-input :min="0" :step="1" label="Bondiis a entregar por ejecución"-->
                <!--                                   source="execution_points"/>-->
                <!--                </v-col>-->
                <!--                <v-col sm="12">-->
                <!--                  <va-number-input :min="0" :step="1" label="Meta de Bondiis a entregar por Ejecución"-->
                <!--                                   source="execution_goal_points"/>-->
                <!--                </v-col>-->

                <v-row>
                    <v-col sm="6">
                        <v-menu v-model="menu1" :close-on-content-click="false"
                                :nudge-right="40" min-width="auto" offset-y transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="formModel.start_date" label="Fecha de inicio"
                                              prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"/>
                            </template>
                            <v-date-picker v-model="formModel.start_date" @input="menu1 = false"/>
                        </v-menu>
                    </v-col>
                    <v-col sm="6">
                        <v-menu ref="menu1" v-model="date2" :close-on-content-click="false" :nudge-right="40"
                                :return-value.sync="formModel.start_time" max-width="290px" min-width="290px"
                                offset-y transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="formModel.start_time"
                                              label="Hora" prepend-icon="mdi-clock-time-four-outline" readonly
                                              v-bind="attrs"
                                              v-on="on"/>
                            </template>
                            <v-time-picker v-if="date2"
                                           v-model="formModel.start_time" format="24hr"
                                           @click:minute="$refs.menu1.save(formModel.start_time)"/>
                        </v-menu>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col sm="6">
                        <v-menu v-model="menu2" :close-on-content-click="false"
                                :nudge-right="40" min-width="auto" offset-y transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="formModel.max_date" label="Fecha de expiración"
                                              prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"/>
                            </template>
                            <v-date-picker v-model="formModel.max_date" @input="menu2 = false"/>
                        </v-menu>
                    </v-col>
                    <v-col sm="6">
                        <v-menu ref="menu2" v-model="date1" :close-on-content-click="false" :nudge-right="40"
                                :return-value.sync="formModel.time" max-width="290px" min-width="290px" offset-y
                                transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="formModel.time"
                                              label="Hora" prepend-icon="mdi-clock-time-four-outline" readonly
                                              v-bind="attrs"
                                              v-on="on"/>
                            </template>
                            <v-time-picker v-if="date1"
                                           v-model="formModel.time" format="24hr"
                                           @click:minute="$refs.menu2.save(formModel.time)"/>
                        </v-menu>
                    </v-col>
                </v-row>


                <v-col sm="12">
                    <va-select-input :choices="mediaOptions" label="Multimedia disponible" multiple required
                                     source="media_enable"></va-select-input>
                </v-col>

                <v-col sm="12">
                    <va-number-input :min="0" :step="1" label="Duración de la misión (minutos)" required
                                     source="active_duration"/>
                </v-col>

                <v-col sm="12">
                    <p>Imágenes de la misión</p>
                </v-col>
                <v-col v-if="item && item.images" sm="12">

                    <div class="d-flex flex-row">
                        <template v-for="(image, iIndex) in item.images">
                            <v-card v-if="item && item.images" :key="iIndex" class="mr-10 d-flex align-center"
                                    max-width="250">
                                <v-img :src="image"
                                       class="align-center" contain max-height="250" max-width="250"/>
                            </v-card>
                        </template>
                    </div>
                </v-col>
                <v-col sm="12">
                    <va-file-input :label="'Imagen'" :required="item && !item.images.length" chips
                                   lg="4"
                                   multiple source="images" v-bind="props" @input="onAddImage"/>
                </v-col>

                <v-col offset="10" sm="2">
                </v-col>


                <v-divider/>


                <v-expansion-panels
                        id="config-ex-panel">

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Parámetros/Variables para cumplir Misión
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-col>
                                <template v-for="param in formModel.validation_params">
                                    <v-col
                                            v-show="['planogram', 'photo_ocr', 'photo_object'].includes(formModel.type) || ['amount_mission_per_target', 'amount_mission_per_user'].includes(param.parameterKey)"
                                            :key="param.id">

                                        <v-card-title :key=param.parameter>{{ param.parameter }}</v-card-title>
                                        <va-select-input v-if="param.parameterType === 'string'" :key=param.id
                                                         v-model="formModel.validation_params[param.id].value"
                                                         :choices="getValidationParamsOptions(param.parameter)"
                                                         items/>
                                        <va-number-input v-else :key=param.id
                                                         v-model="formModel.validation_params[param.id].value"/>
                                    </v-col>
                                </template>
                            </v-col>


                            <va-array-input v-show="false" id="validation_params_input" label=" "
                                            source="validation_params">
                            </va-array-input>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                                v-show="['planogram', 'photo_ocr', 'photo_object'].includes(formModel.type)">
                            Parámetros de validación de la foto
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-col class="ms-12">
                                <v-card-title class="mb-5">Cantidad mínima de productos</v-card-title>
                                <template v-for="(param, index) in photo_validation_mp_fields">
                                    <v-row :key="index">
                                        <v-col>
                                            <va-text-input v-model="photo_validation_mp_fields[index].item"
                                                           label="Nombre del parámetro"/>
                                        </v-col>
                                        <v-col>
                                            <va-number-input v-model="photo_validation_mp_fields[index].quantity"
                                                             label="Cantidad"/>
                                        </v-col>
                                        <v-col>
                                            <va-action-button
                                                    color="red"
                                                    icon="mdi-delete"
                                                    label="Eliminar"
                                                    large
                                                    text
                                                    @click="deletePhotoParam(index)"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                                <v-btn color="primary" @click="addPhotoParam">Añadir parámetro</v-btn>
                                <v-btn v-show="false" @click="mergePhotoParams">Merge params</v-btn>
                            </v-col>
                            <va-array-input v-show="false" id="photo_params_input" v-slot="props" label=" "
                                            source="photo_validation_params">
                                {{ props }}
                            </va-array-input>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>


                <v-row class="col-sm-12 justify-end">
                    <div class="my-10 mx-5">
                        <va-save-button v-if="this.item" label="Actualizar reto"></va-save-button>
                        <v-btn v-else :loading=createChallenge class="ma-5" color="blue" @click="saveChallenge">Guardar
                            reto
                        </v-btn>
                    </div>

                </v-row>

                <div v-if="challengeResponse !== null" class="mb-12 lighten-1">
                    <v-card-title> Configuración de recursos del reto</v-card-title>

                    <v-expansion-panels id="config-challenge" class="mt-12">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Registrar pregunta
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <questions-form fromChallenge="true"/>
                                <QuestionsList :challenge-id="challengeResponse.id" :is-modal="false"/>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Agregar targets
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <create-target :challengeId="challengeResponse.id"
                                               :client_id="challengeResponse.client.id"
                                               :is-modal="false"
                                               @closeTargetModal="closeTargetModal"></create-target>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Cargar targets y usuarios desde excel
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-file-input :disabled="loadingData" :loading="loadingData" accept=".xlsx"
                                              @change="changeInputFile"/>
                                <v-btn :disabled="jsonData === null" :loading="loadingData" color="primary"
                                       @click="uploadData">
                                    Cargar datos
                                </v-btn>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Registrar recompensa
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <CreateRewardsForm :client="challengeResponse.client.id" :edit="null"
                                                   :is-modal="false"/>
                                <RewardsList :challenge-id="challengeResponse.id" :client="challengeResponse.client.id"
                                             :is-modal="false"></RewardsList>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                Registrar notificaciones
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <CreateNotificationForm :challenge-id="challengeResponse.id" :edit="null"
                                                        :is-modal="false"/>
                                <NotificationsList :challenge-id="challengeResponse.id"
                                                   :is-modal="false"></NotificationsList>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-row class="col-sm-12 justify-end my-16">

                        <v-btn v-show="formModel.type === 'multichallenge'" class="mr-4" color="cyan"
                               @click="toCreate">
                            Añadir sub challenge
                        </v-btn>

                        <v-btn v-show="is_sub_challenge" class="mr-4" color="cyan"
                               @click="reloadPage">
                            Añadir otro sub challenge
                        </v-btn>


                        <v-btn color="primary" @click="exit">
                            Finalizar y volver a la lista
                        </v-btn>

                    </v-row>
                </div>


            </va-form>
        </div>
        <snack-alert :message="message" :show-snack="message.show" @closeSnack="statusSnack"/>
    </div>
</template>

<script>
import SnackAlert from "@/components/common/SnackAlert.vue";

import CreateTarget from "@/components/common/targets/CreateTarget";
import CreateRewardsForm from "@/components/common/rewards/CreateForm.vue";
import RewardsList from "@/components/common/rewards/RewardsList";
import CreateNotificationForm from "@/components/common/notifications/CreateForm"
import NotificationsList from "@/components/common/notifications/NotificationsList";

import axios from "axios";
import QuestionsList from "@/components/common/questions/QuestionsList";

const XLSX = require('xlsx');


export default {
    components: {
        NotificationsList,
        CreateRewardsForm,
        CreateNotificationForm,
        SnackAlert,
        QuestionsList,
        CreateTarget,
        RewardsList
    },
    props: ["id", "title", "item", "parentId", "parentClientId"],
    data() {

        return {
            is_sub_challenge: ![null, undefined].includes(this.parentId),
            userPropertyModel: null,
            listCompanies: [],
            jsonData: null,
            createClientModal: false,
            loadingData: false,
            photo_validation_mp_fields: [],
            photo_validation_mp: "",
            clientTargets: [],
            showCreateQuestions: false,
            showQuestionsModal: false,
            showRewardsModal: false,
            challengeResponse: null
            // {
            //   "photo_categories_ser": [],
            //   "id": 77,
            //   "points_to_win": 0,
            //   "client": {
            //     "id": 2,
            //     "company_name": "Nutresa",
            //     "rewards": [],
            //     "created_at": "2022-03-28T14:58:52+00:00",
            //     "updated_at": "2022-03-28T14:58:52+00:00"
            //   },
            //   "quiz": [],
            //   "description": "borrar",
            //   "amount_executions": 2.0,
            //   "budget": 1000.0,
            //   "images": [
            //     "https://storage.googleapis.com/bondii/core/challenges/f381a5b77512b8138ca3196756ae3e87.jpg"
            //   ],
            //   "conditions": [],
            //   "max_date": "2023-02-18T00:00:00+00:00",
            //   "start_date": "2023-02-17T00:00:00+00:00",
            //   "time": null,
            //   "start_time": null,
            //   "name": "Challenge de prueba",
            //   "status": "pending",
            //   "validated": null,
            //   "added": null,
            //   "media": [],
            //   "answers": [],
            //   "challenge_quiz": null,
            //   "type": "photo_evidence",
            //   "photo_validation_params": [],
            //   "collective": true,
            //   "validation_params": [],
            //   "audience": [],
            //   "client_goal": 10.0,
            //   "client_goal_description": "w",
            //   "loyal_points_to_win": 0.0,
            //   "segmentation": null,
            //   "photo_categories": [],
            //   "execution_points": 0.0,
            //   "execution_goal_points": 0.0,
            //   "active_duration": 4.0,
            //   "challenge_rewards": [],
            //   "challenge_questions": [],
            //   "created_at": "2023-02-17T01:02:42+00:00",
            //   "updated_at": "2023-02-17T01:02:42+00:00"
            // }
            ,
            createChallenge: false,
            stepper: 1,
            docTypes: [
                {value: "cc", text: "Cédula"},
                {value: "ce", text: "Carnet extranjería"},
                {value: "nit", text: "NIT"},
            ],
            challengeTypes: [
                {value: "photo_ocr", text: "Foto OCR (texto)"},
                {value: "photo_object", text: "Foto Reconocimiento Obj"},
                {value: "photo_evidence", text: "Foto Evidencia"},
                {value: "questions", text: "Encuesta"},
                // {value: "invoice_tag", text: "Etiquetado de Factura"},
                // {value: "image_tag", text: "Etiquetado de imágen"},
                {value: "planogram", text: "planograma"},
                {value: "multichallenge", text: "Multichallenge"},
            ],
            mediaOptions: [
                {value: "multifoto", text: "Multifoto"},
                {value: "video", text: "Video"},
                // {value: "foto", text: "Foto"}
            ],
            userProperties: [
                {value: "gender", text: "Genero"},
                {value: "age", text: "Edad"},
                {value: "transport", text: "Transporte"},
                {value: "category", text: "Categoría"},
                {value: "role", text: "Rol"},
                {value: "company", text: "Compañia"},
                {value: "role_company", text: "Rol en la compañía"},
                {value: "role_company", text: "Rol en la compañía"},
                {value: "phone", text: "Número celular"},
                {value: "email", text: "Correo"},
            ],
            operators: [
                {value: "and", text: "Y (AND, Debe cumplir)"},
                {value: "or", text: "Ó (OR, opcional)"},
            ],
            parameterTypes: [
                {value: "string", text: "Texto"},
                {value: "number", text: "Número"},
            ],
            comparisons: [
                {value: "=", text: "Igual"},
                {value: "", text: "Existe"},
                {value: ">", text: "Mayor que"},
                {value: "<", text: "Menor que"},
                {value: "<=", text: "Menor o igual"},
                {value: ">=", text: "Mayor o igual"},
                {value: ">= between <=", text: ">= Entre <="},
                {value: "like %", text: "Empieze en %"},
                {value: "% like", text: "% Termine en"},
                {value: "% like %", text: "% Contenga %"},
            ],
            transports: [
                {value: 'a pie', text: 'A pie'},
                {value: 'moto', text: 'Moto'},
                {value: 'carro', text: 'Carro'},
                {value: 'bicicleta', text: 'Bicicleta'},
            ],
            genders: [
                {value: "masculino", text: "Masculino"},
                {value: "femenino", text: "Femenino"},
                {value: "otro", text: "Otro"},
            ],
            correct: [
                {value: "1", text: "1"},
                {value: "2", text: "2"},
                {value: "3", text: "3"},
                {value: "4", text: "4"},
            ],
            date1: false,
            date2: false,
            menu1: false,
            menu2: false,
            value: 1,
            formModel: {
                name: "",
                budget: 0,
                description: "",
                time: "00:00",
                start_time: "00:00",
                instructions: [],
                conditions: [],
                segmentation: null,
                media_enable: ["photo"],
                validation_params: [
                    {
                        id: 0,
                        value: "",
                        comparer: "",
                        parameter: "model",
                        parameterKey: "model",
                        parameterType: "string"
                    }, {
                        id: 1,
                        value: "",
                        comparer: "=",
                        parameter: "amount_mission_per_target",
                        parameterKey: "amount_mission_per_target",
                        parameterType: "number"
                    }, {
                        id: 2,
                        value: "",
                        comparer: "=",
                        parameter: "provider",
                        parameterKey: "provider",
                        parameterType: "string"
                    },
                    {
                        id: 3,
                        value: "",
                        comparer: "=",
                        parameter: "amount_mission_per_user",
                        parameterKey: "amount_mission_per_user",
                        parameterType: "number"
                    }
                ],
                photo_validation_params: [{
                    id: 0,
                    value: "",
                    comparer: "",
                    parameter: "minimun_products",
                    parameterKey: "minimun_products",
                    parameterType: "string"
                }],
                edit_target: false,
                execution_points: 0,
                execution_goal_points: 0,
                is_sub_challenge: ![null, undefined].includes(this.parentId),
                client_goal_description: "",
                client_goal: 0,
                collective: true,
                amount_executions:1,

            },
            external: false,
            clientId: null,
            targets: [],
            message: {
                show: false,
                timeout: 2000,
                text: 'Challenge created successfully',
                type: 'success'
            },
        };

    },

    async created() {
        this.formModel.time = this.item?.time;
        this.formModel.start_time = this.item?.start_time.split('T')[0];
        this.formModel.start_date = this.item?.start_date.split('T')[0];
        this.formModel.max_date = this.item?.max_date.split('T')[0];
        this.getClient(this.item)
        // await this.getTargets()
    },
    mounted() {
        console.log('mounted')
        if (this.item) {
            console.log(this.item)
            this.challengeResponse = this.item
            this.formModel = this.item

            const params = this.item.photo_validation_params[0].value.split(',')
            for (let i = 0; i < params.length; i += 2) {
                this.photo_validation_mp_fields.push({item: params[i], quantity: params[i + 1]})
            }
            console.log(this.formModel.validation_params)
        } else {
            console.log('no item')
        }

        if (this.parentId) {
            this.formModel.client = this.parentClientId;
            this.challengeTypes = this.challengeTypes.filter((e)=>e.value !=='multichallenge')
            console.log('parentid exist ' + this.parentId)
        }


    },

    methods: {

        reloadPage(){
            window.location.reload();
        },

        toCreate() {
            this.$router.push(`/challenges/${this.challengeResponse.id}/client=${this.challengeResponse.client.id}/sub-challenge`);
        },

        addOrRemoveMedia(remove, media) {
            console.log(this.formModel.media_enable)
            if (remove) {
                this.formModel.media_enable.filter((item) => item !== media)
            } else {
                this.formModel.media_enable.push(media)

            }
            console.log(this.formModel.media_enable)
        },
        async uploadData() {
            this.loadingData = true
            try {
                const response = await axios.post(
                    `https://ms-invoice.bondii.net/v1/flow2`,
                    // 'http://localhost:3010/v1/flow2',
                    {
                        data: this.jsonData,
                        client: this.challengeResponse?.client.id || this.clientId,
                        challengeId: this.challengeResponse.id || this.item.id,
                        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
                        client_secret: process.env.VUE_APP_AUTH0_CLIENT_SECRET
                    })

                console.log(response)
                this.message.show = true
                this.message.text = 'Datos creados con éxito'
                this.message.type = 'success'
            } catch (e) {
                console.log(e.toString())
                this.message.show = true
                this.message.text = `Error, verifica el archivo, ${e}`
                this.message.type = 'error'
            }
            this.loadingData = false
        },

        changeInputFile(file) {

            console.log(file)
            const reader = new FileReader();
            reader.onload = () => {
                const data = reader.result;
                const workbook = XLSX.read(data, {type: 'binary'});
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const json = XLSX.utils.sheet_to_json(worksheet, {raw: false});
                this.jsonData = json
                console.log(json);
            };
            reader.readAsBinaryString(file);
        }
        ,
        deletePhotoParam(index) {
            this.photo_validation_mp_fields.splice(index, 1)
        },
        //slugify parameter key
        addPhotoParam() {
            this.photo_validation_mp_fields.push({item: "", quantity: 1})
        },
        mergePhotoParams() {
            this.photo_validation_mp_fields.forEach((param) => {


                if (param.item.replaceAll(' ', '') !== "") {
                    this.photo_validation_mp += `,${param.item}`
                    this.photo_validation_mp += `,${param.quantity}`
                }

            });

            this.formModel.photo_validation_params[0].value = this.photo_validation_mp;
            console.log(this.formModel.photo_validation_params[0].value);
        },


        getValidationParamsOptions(param) {

            switch (param) {
                case 'model':
                    return ["carnicos"]
                case 'provider':
                    return ["external"]
                default:
                    return ["carnicos"]
            }

        },
        closeModalQuestions(status) {
            this.showQuestionsModal = status.status;
        },
        closeModalReward(value) {
            this.showRewardsModal = value.status
        },
        async showAndAddTargets() {
            try {
                const {data} = await this.$admin.http.get(`/targets/${this.challengeResponse.client.id}/client`);
                this.clientTargets = data;
                console.log(this.clientTargets)
                this.targetModal = true;

                // show modal
            } catch ({response}) {
                this.$snackbar.error(response.data.message);
            }
        },

        closeTargetModal(status) {
            this.targetModal = status;
        },
        async saveChallenge() {
            this.mergePhotoParams();

            let formData = new FormData();

            for (const key in this.formModel) {
                console.log(this.formModel)
                console.log(key);
                if (key === 'images') {
                    formData.append(key, this.formModel[key][0])
                } else if (['validation_params', 'conditions', 'photo_validation_params', 'audience', 'instructions'].includes(key)) {
                    if (!(['planogram', 'photo_object', 'photo_ocr'].includes(this.formModel.type))) {
                        this.formModel.validation_params = []
                        this.formModel.photo_validation_params = []
                    }
                    this.formModel[key].forEach((element, index) => {
                        for (const elementKey in element) {
                            formData.append(`${key}[${index}][${elementKey}]`, this.formModel[key][index][elementKey])

                        }
                    });
                } else {
                    formData.append(key, this.formModel[key])
                }
            }

            this.createChallenge = true;
            try {
                await this.$admin.http.post(`/challenges`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then((response) => {
                        this.challengeResponse = response.data
                    })

                this.message.show = true
                this.message.text = 'Creado con exito'
                this.message.type = 'success'
                this.stepper = 4

                if(this.is_sub_challenge){

                    const subChallengeToAdd = {
                        challengesIds:[this.challengeResponse.id],
                        parentChallengeId: this.parentId,

                    };

                    try {
                    await this.$admin.http.post('/challenges/sub-challenge', subChallengeToAdd, {headers: {'Content-Type': 'application/json'}} )
                    this.message.show = true
                    this.message.text = 'Asignado correctamente'
                    this.message.type = 'success'
                    }catch (e) {
                        this.message.show = true
                        this.message.text = e.toString()
                        this.message.type = 'error'
                    }
                }
            } catch (e) {
                console.log(e.toString())
                this.message.show = true
                this.message.text = e.toString()
                this.message.type = 'error'
            }
            this.createChallenge = false;


        },

        onSaved() {
            console.log('se guardó:' + this.item);
            this.stepper = 4
        },
        onChange(value) {
            // this.body.name = value;
            console.log(value)
        },
        statusSnack(value) {
            this.message.show = value.status
        },
        updateModel({index, item}, property) {
            this.formModel[property][index].parameterKey = item.parameter.toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '');
        },
        async exit() {
            await this.$router.push('/challenges')
        },

        getClient({client}) {
            this.clientId = client.id
        },
        async onAddImage(file) {
            console.log(file);
            // let binaryFile = await this.toBase64(file[0]);

        },
    },
    computed: {
        toCreateSubChallenge() {
            return this.$admin.getResourceLink({
                name: "users",
                action: "create",
                icon: "mdi-plus",
                text: "Create new user"
            }).link
        },
        color() {
            switch (this.value) {
                case 0:
                    return 'blue-grey'
                case 1:
                    return 'teal'
                case 2:
                    return 'brown'
                case 3:
                    return 'indigo'
                default:
                    return 'blue-grey'
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.form-container {
  max-height: 80vh !important;
}


</style>
