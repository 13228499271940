<template>
  <va-create-layout :title="title">
    <pqrs-form :item="item"></pqrs-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
