import objectToFormData from "vuetify-admin/src/providers/utils/objectToFormData";

const GET_POINTS = "getPoints";
const GIVE_POINTS = "givePoints";
const TRANSFER_POINTS = "transferPoints";

export default (httpClient, params = {}) => {
  params = {
    routes: {
      create: "create-points",
      user_points: "user/points",
      transfer: "points/transfer",
    },
    getCredentials: () => {
      return {
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        client_secret: process.env.VUE_APP_AUTH0_CLIENT_SECRET,
      };
    },
    ...params,
  };

  let {
    routes,
    getCredentials,
  } = params;

  return {
    [GET_POINTS]: async ({ uuid }) => {
      const formData = objectToFormData({
        ...getCredentials(),
        user_id: uuid.uuid
      });
      return await httpClient.post(routes.user_points, formData);
    },
    [TRANSFER_POINTS]: async ({ issuing, receiver, points, details, transaction_type }) => {
      const formData = objectToFormData({
        ...getCredentials(),
        issuing, receiver, points,
        details, transaction_type
      });
      return await httpClient.post(routes.transfer, formData);
    },
    [GIVE_POINTS]: async ({ user_id, points, details, transaction_type, currency_type }) => {
      const formData = objectToFormData({
        ...getCredentials(),
        user_id: user_id.uuid, points,
        details, transaction_type, currency_type
      });
      return await httpClient.post(routes.create, formData);
    },
  };
};
