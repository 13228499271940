<template>
  <div>
    <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
      <v-container>
        <v-card>
          <v-card-title class="pl-4 pt-4">
            Formulario para añadir preguntas
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="10"
                  sm="8"
                >
                  <v-text-field
                    v-model="body.question"
                    label="Pregunta"
                    filled
                  />
                </v-col>
                <v-col
                  cols="2"
                  sm="3"
                >
                  <v-checkbox
                    v-model="body.multiple"
                    label="Pregunta Multiple"
                    hide-details
                  />
<!--                  {{body.multiple}}-->
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="body.position"
                    :items="positions"
                    item-value="value"
                    item-text="text"
                    filled
                    label="En que lugar se preguntara"
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="body.type"
                    :items="types"
                    item-value="value"
                    item-text="text"
                    filled
                    label="La pregunta tendrá una respuesta de tipo"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-if="body.multiple"
                    v-model="options"
                    filled
                    label="Escriba las opciones de respuesta separadas por coma (,)"
                    :value="options"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading=createQuestion class="ma-5" color="blue" @click="saveQuestion">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </va-form>
    <snack-alert :show-snack="message.show" :message="message" @closeSnack="statusSnack"/>
  </div>
</template>

<script>
import SnackAlert from "../../components/common/SnackAlert";

export default {
  props: ["id", "item", {
    fromChallenge: Boolean
  }],
  components: {
    SnackAlert
  },
  data: () => {
    return {
      body: {
        question: '',
        multiple: false,
        answers: [],
        enabled: true,
        position: 'end',
        type: 'string'
      },
      options: '',
      positions: [
        { text: 'INICIO', value: 'start' },
        { text: 'MITAD', value: 'middle' },
        { text: 'FINAL', value: 'end' }
      ],
      types: [
        { text: 'Texto', value: 'string' },
        { text: 'Número', value: 'number' },
        { text: 'Verdadero o Falso', value: 'boolean' }
      ],
      createQuestion: false,
      message: {
        show: false,
        timeout: 2000,
        text: 'Reward create successfully',
        type: 'success'
      },
    }
  },
  mounted() {},
  methods: {
    statusSnack (value) {
      this.message.show = value.status
    },
    async saveQuestion () {
      this.createQuestion = true
      this.body.answers = this.body.multiple ? this.options.split(',') : []
      try {
        await this.$admin.http.post(`/questions/`, this.body, {headers: { 'Content-Type': 'application/json' }})
        if(this.fromChallenge) {
          await this.$router.push('/questions')
        }
        this.message.show = true
        this.message.text = 'Creado con exito'
        this.message.type = 'success'
      } catch (e) {
        this.message.show = true
        this.message.text = e.toString()
        this.message.type = 'error'
      }
      this.createQuestion = false
    }
  }
};
</script>
