<template>
  <va-create-layout>
    <challenges-form :title="title" :item="item" :parentId="parentId" />
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"],
  data(){
    return{
      parentId: this.$route.params.parentId,
    }
  }
};
</script>
