import Vue from "vue";
import AdminLayout from "@/layouts/Admin";
import Dashboard from "@/views/Dashboard";
import Error from "@/views/Error";
import i18n from "@/i18n";
import Label from "@/resources/label/List";
import ListByClient from "@/resources/challenges/ListByClient";
import subChallenge from "@/resources/challenges/SubChallenge";

/**
 * Error component
 */
Vue.component("Error", Error);

export default {
    path: "",
    component: AdminLayout,
    meta: {
        title: i18n.t("routes.home"),
    },
    children: [
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard,
            meta: {
                title: i18n.t("routes.dashboard"),
            },
        },
        {
            path: "/execution/:id/label",
            component: Label,
            meta: {
                title: i18n.t("routes.labeler"),
                authenticated: true,
                breadcrumb: 'label'
            },
        },
        {
            path: "/users/challenges/:id",
            component: ListByClient,
            meta: {
                title: i18n.t("routes.my-challenges"),
                authenticated: true
            },
        },
        {
            path: "/challenges/:parentId/client=:clientId/sub-challenge",
            component: subChallenge,
            meta: {
                title: "Creación sub reto",
            },
        },
        {
            path: "*",
            component: Error,
            meta: {
                title: i18n.t("routes.not_found"),
            },
        },
    ],
};
