<template>
  <v-card>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="rewardsByChallenge"
          :loading="load"
          loading-text="Espera por favor ..."
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="font-weight-bold">Recompensas del reto</v-toolbar-title>
            <v-spacer />
            <v-dialog v-model="openRewardChallenge" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="rewardsByClient"
                >
                  Añadir Recompensa
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="font-weight-bold">Relacionar recompensa con reto</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                            v-model="listRewards"
                            :loading="loadRewardsInField"
                            :items="rewardsClient"
                            multiple
                            item-text="name"
                            item-value="id"
                            label="Recompensas del cliente"
                            chips
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn

                      color="error"
                      text
                      @click="openRewardChallenge = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      color="success"
                      text
                      @click="addRewardToChallenge"
                      :loading="saveRelation"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="enableReward(item)"
              :color="[null, false].includes(item.enabled) ? 'red' : 'green'"
          >
            {{ [null, false].includes(item.enabled) ? 'mdi-close' : 'mdi-check' }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="is-modal" text color="primary" @click="cancel">Cerrar</v-btn>
    </v-card-actions>
    <snack-alert :show-snack="message.show" :message="message" @closeSnack="statusSnack" />
  </v-card>
</template>

<script>
import SnackAlert from "@/components/common/SnackAlert";

export default {
  props: ['challengeId', 'client', 'is-modal'],
  components: {
    SnackAlert
  },

  name: "RewardsList",
  data: () => {
    return {
      show: false,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nombre', value: 'reward.name' },
        { text: 'Activo', value: 'enabled'},
        { text: 'Bondii Coins (BC)', value: 'reward.amount_loyalty' },
        { text: 'Dinero Local ($)', value: 'reward.amount_money' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      rewardsByChallenge: [],
      load: false,
      rewardsClient: [],
      openRewardChallenge: false,
      saveRelation: false,
      message: {
        show: false,
        timeout: 2000,
        text: 'Reward create successfully',
        type: 'success'
      },
      loadRewardsInField: false,
      listRewards: []
    }
  },
  methods: {
    cancel () {
      this.$emit('closeModalReward', { status: false })
      this.load = false
    },
    enableReward (item) {
      this.$admin.http.put(`/challenge-reward/${item.id}/enabled`, { enabled: !item.enabled }, {headers: { 'Content-Type': 'application/json' }}).then(async () => {
        this.message.show = true
        this.message.type = 'success'
        this.message.text = 'Reward update successfully'
        this.saveRelation = false
        await this.rewardsByChallengeAPI()
      }).catch((error) => {
        this.message.show = true
        this.message.text = error.toString()
        this.message.type = 'error'
        this.saveRelation = false
      })
    },
    async addRewardToChallenge () {
      this.saveRelation = true
      const responses = []
      for (const reward of this.listRewards) {
        const { data } = await this.$admin.http.post(`/challenge-reward/`, {
          reward: reward,
          challenge: this.challengeId,
          enabled: true
        }, {headers: { 'Content-Type': 'application/json' }})
        if (Object.values(data).includes('error')) {
          responses.push(reward)
        }
      }

      this.message.show = true
      if (responses.length > 0) {
        this.message.text = "Error in rewards" + responses.length
        this.message.type = 'error'
      } else {
        this.openRewardChallenge = false
        this.message.type = 'success'
        this.message.text = 'Reward update successfully'
        await this.rewardsByChallengeAPI()
      }
      this.saveRelation = false

    },
    async rewardsByChallengeAPI () {
      this.load = true
      const { data } = await this.$admin.http.get(`/challenge-reward/${this.challengeId}/challenge`)
      this.rewardsByChallenge = data
      this.load = false
    },
    async rewardsByClient () {
      this.loadRewardsInField = true
      const { data } =  await this.$admin.http.get(`/rewards/${this.client}/client`)
      this.rewardsClient = data
      this.loadRewardsInField = false
    },
    statusSnack (value) {
      this.message.show = value.status
    }
},
  mounted() {
    this.rewardsByChallengeAPI()
  }
}

</script>

<style scoped>

</style>