<template>
  <v-container>
    <v-card elevation="1">
      <v-card-text>
        <va-show :item="item">
          <v-container>
            <v-row>
              <v-col>
                <va-field source="name"/>
                <va-field source="description"/>
                <va-field source="client.company_name" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <va-field source="status"/>
              </v-col>
              <v-col>
                <va-field source="points_to_win" />
              </v-col>
              <v-col>
                <va-field source="start_date" type="date" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <strong>Parámetros de Validación</strong>
                <ul>
                  <li v-for="target in item.photo_validation_params" :key="target.id">
                    <strong>{{ target.parameter }}:</strong> {{ target.value }}
                  </li>
                </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>Misiones</h3>
                <v-dialog v-model="dialog" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Validación de ejecución</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-img :src="editedItem.picture" label="Imagen"></v-img>
                          </v-col>
                          <v-col cols="12">
                            <va-number-input :disabled="editedItem.validated !== null"
                                             v-model="editedItem.indicator" label="Total acumulado"/>
                          </v-col>
                          <v-col v-if="editedItem.answers && editedItem.answers.length" cols="12">
                            <strong>Respuestas</strong>
                            <ul>
                              <li v-for="(answer, llave) in editedItem.answers" :key="llave">
                                <strong>{{ answer.question }}:</strong> {{ answer['correct-answer'] }}
                              </li>
                            </ul>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="dark darken-1" text @click="close">
                        Cancelar
                      </v-btn>
                      <v-btn color="red darken-1" text @click="invalidate">
                        Invalidar
                      </v-btn>
                      <v-btn :disabled="editedItem.validated !== null" color="blue darken-1" text @click="save">
                        {{item.type === 'questions'?'Validar':'Guardar'}}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-data-table
                  :headers="headersMissions"
                  :items="missionsByUser"
                  :items-per-page="50"
                  class="elevation-1"
                  :loading="missionsByUser.length === 0"
                  loading-text="Espera por favor ..."
                >
                  <template v-slot:top>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="detailMissions(item.missions)">mdi-eye</v-icon>
                  </template>
                </v-data-table>
                <v-dialog v-model="showMissions" fullscreen hide-overlay transition="dialog-bottom-transition">
                  <v-card>
                    <v-toolbar
                      dark
                      color="primary"
                    >
                      <v-btn
                        icon
                        dark
                        @click="showMissions = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>Misiones</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn
                          dark
                          text
                          @click="cancel()">
                          Cancelar
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                      <v-row justify="center">
                        <v-col cols="2">
                          <p class="display-1">Misiones: <span>{{ missionsAndExecutions.length }}</span></p>
                          <v-list rounded style="height: 85vh; overflow-y: auto">
                            <v-list-item-group
                              v-model="selectedMission"
                              color="primary"
                            >
                              <v-list-item
                                v-for="(item, i) in missionsAndExecutions"
                                :key="i"
                                @click="loadExecutions(item)"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <p>Id: <span class="font-weight-bold">#{{ item.id }}</span></p>
                                    <span>Estado: <span class="font-weight-bold text-capitalize">{{item.status}}</span></span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-col>
                        <v-divider vertical/>
                        <v-col style="height: 90vh; overflow-y: auto" class="d-flex flex-column">
                          <v-row style="height: 5vh">
                            <div class="d-flex flex-row justify-space-between" style="width: 100%">
                              <div class="d-flex flex-column pl-5">
                                <span class="font-weight-bold">{{ target.id }} - {{ target.name }}</span>
                                <span class="caption">{{ target.city }} - {{ target.regional }} - {{ target.address }}</span>
                              </div>
                              <div class="d-flex flex-column pl-5" v-if="missionStatus !== 'rejected'">
                                <span>Fecha actual exhibición: <span class="font-weight-bold">{{ dateStart }}</span></span>
                                <span class="caption">Fecha siguiente exhibición: <span class="font-weight-bold">{{ dateNext }}</span></span>
                              </div>
                            </div>
                          </v-row>
                          <v-divider/>
                          <v-row cols="12" style="height: 55vh" class="pb-2">
                            <v-col cols="4">
                              <v-img v-if="executionData !== null" :src="executionData.photo" height="50vh" contain>
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="blue"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-col>
                            <v-divider vertical inset></v-divider>
                            <v-col>
                              <v-container>
                                <p v-if="executionData !== null">Ejecución # {{executionData.id}}</p>
                                <v-row>
                                  <v-col style="height: 48vh">
                                    <v-card class="pa-2" outlined tile height="80%" style="overflow-y: auto">
                                      <v-card-title>Metadata</v-card-title>
                                      <v-card-text v-if="executionData !== null">
                                        <v-expansion-panels>
                                          <v-expansion-panel v-for="(entrie, i) in Object.entries(executionData.metadata)" :key="i">
                                            <v-expansion-panel-header class="text-left">
                                              {{entrie[0]}}
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content class="green--text">

                                              <pre>{{ entrie[1] | pretty(4) }}</pre>


                                            </v-expansion-panel-content>
                                          </v-expansion-panel>
                                        </v-expansion-panels>
                                      </v-card-text>
                                    </v-card>
                                    <v-card class="pa-2 d-flex align-center justify-center" outlined tile height="20%">
                                      <div v-if="executionData !== null">
                                        <v-btn color="red" @click="changeStatus = true">Rechazar Ejecución</v-btn>
                                        <v-btn class="ml-4" color="green" @click="updateStatusMission('finished')"
                                               :loading="approved"
                                        >Aprobar Ejecución</v-btn>
                                      </div>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-col>
                          </v-row>
                          <v-divider/>
                          <v-row style="height: 30vh" class="pt-5">
                            <v-col cols="12">
                              <div class="d-flex flex-column justify-end">
                                <div v-if="dataMission.length > 0">
                                  <p class="display-1 pl-4 mb-5">Ejecuciones</p>
                                  <v-data-table :headers="headers" :items="dataMission" :items-per-page="2"
                                                class="elevation-1">
                                    <template v-slot:top>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                      <v-icon small class="mr-2" @click="showExecution(item)">mdi-eye</v-icon>
                                    </template>
                                  </v-data-table>
                                </div>
                                <p v-else class="text-center d-flex align-center justify-center">
                                  No tenemos ejecuciones en este momento
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <!-- <div class="my-3" v-for="(mission) in item.executions" :key="mission.id">
                  <h4>Misión de usuario {{ mission.user_uuid.name }} {{ mission.user_uuid.lastname }} {{ mission.user_uuid.uuid }}</h4>
                  <v-data-table :headers="headers" :items="mission.executions" :items-per-page="50"
                               class="elevation-1">
                    <template v-slot:top>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                      <v-icon small class="mr-2" @click="openNewTab(item)">mdi-tag</v-icon>
                    </template>
                  </v-data-table>
                </div> -->
              </v-col>
            </v-row>
          </v-container>
        </va-show>
      </v-card-text>
    </v-card>
    <v-dialog v-model="changeStatus" max-width="500px">
      <v-card v-if="executionData !== null">
        <v-card-title>
          <span class="text-h5">Ejecución #{{ executionData.id }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p>¿Porque se rechaza?</p>
                <v-autocomplete v-model='message' :items='messages' label='Motivos' />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="changeStatus = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="updateStatusMission('rejected')" :loading="rejected">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { addHours, format } from 'date-fns'
import { es } from 'date-fns/esm/locale'
export default {
  props: ["item"],
  data () {
    return {
      dialog: false,
      editedIndex: -1,
      editedItem: {
        picture: '',
        indicator: 0,
      },
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Indicador',
          align: 'start',
          sortable: false,
          value: 'indicator',
        },
        {
          text: 'Validado',
          align: 'start',
          sortable: false,
          value: 'validated',
        },
        {
          text: 'Ejecución #',
          align: 'start',
          sortable: false,
          value: 'current_execution',
        },
        {
          text: 'Finalizada',
          align: 'start',
          sortable: false,
          value: 'finisher',
        },
        {
          text: 'Acciones',
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ],
      headersMissions: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'userInfo.fullName',
        },
        {
          text: 'Misiones',
          align: 'start',
          sortable: false,
          value: 'missions.length',
        },
        {
          text: 'Finalizadas',
          align: 'start',
          sortable: false,
          value: 'filter.finished.length',
        },
        {
          text: 'Parciales',
          align: 'start',
          sortable: false,
          value: 'filter.partial_finished.length',
        },
        {
          text: 'Pendientes',
          align: 'start',
          sortable: false,
          value: 'filter.pending.length',
        },
        {
          text: 'Rechazadas',
          align: 'start',
          sortable: false,
          value: 'filter.rejected.length',
        },
        {
          text: 'En Revisión',
          align: 'start',
          sortable: false,
          value: 'filter.review_pending.length',
        },
        {
          text: 'Acciones',
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ],
      missions: null,
      missionsByUser: [],
      showMissions: false,
      missionsAndExecutions: [],
      selectedMission: null,
      dataMission: {},
      executionData: null,
      executionView: {},
      changeStatus: false,
      messages: [
        'No cumple con la cantidad requerida',
        'La exhibición se encuentra repetida',
        'No contiene los elementos descritos para esta exhibición',
        'No corresponde a punto de venta',
        'No corresponde a punto de pago'
      ],
      message: '',
      approved: false,
      rejected: false,
      target: {},
      dateStart: '',
      dateNext: '',
      missionStatus: ''
    }
  },
  watch: {
    /* selectedMission: function (val) {
      if (val === 0) {
        this.dataMission = this.missionsAndExecutions[0]
      }
    } */
  },
  async mounted() {
    await this.missionGroupByUser()
    /* this.$admin.http.get(`/missions/challenge/${this.item.id}`).then(({data}) => {
      this.missions = data
    }); */
  },
  methods: {
    editItem: function (item) {
      this.editedItem = Object.assign({}, item)
      this.editedItem.picture = this.editedItem.photo
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {
          picture: '',
          indicator: 0,
        })
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedItem.picture) {
        this.$admin.dataProvider.update('executions', {
          id: this.editedItem.id,
          data: {
            indicator:this.editedItem.indicator
          }
        })
      }
      this.close()
    },
    invalidate () {
      if (this.editedItem.picture) {
        this.$admin.dataProvider.update('executions', {
          id: this.editedItem.id,
          data: {
            validated:false
          }
        })
      }
      this.close()
    },
    openNewTab(item){
      window.open('/execution/'+item.id+'/label','_blank');
    },
    async missionGroupByUser () {
      let self = this
      await this.$admin.http.get(`/missions/${this.item.id}/challenge`).then(({data}) => {
        self.missions = data
      });

      let missionsUser = _.chain(this.missions)
        // Group the elements of Array based on `color` property
        .groupBy("user_uuid.id")
        // `key` is group's name (color), `value` is the array of objects
        .map((value, key) => ({ id: key, missions: value }))
        .value()

      let userCount = 0

      missionsUser.forEach((user) => {
        const statusUser = this.filterByStatusMissions(user.missions)
        user.userInfo = (user.missions[user.missions.length - 1]).user_uuid
        user.userInfo.fullName = `${user.userInfo.name} ${user.userInfo.lastname}`
        user.filter = statusUser
        userCount++
      })

      this.missionsByUser = missionsUser
      this.missionsByUser.countStatus = this.totalMissionByStatus(missionsUser)
      this.missionsByUser.countStatus.total = Object.values(this.missionsByUser.countStatus).reduce((total, num) => total + num)
      this.missionsByUser.countStatus.users = userCount
      // console.log(Object.values(sumStatus).reduce(sum))

    },
    filterByStatusMissions (missions) {
      const statusses = ['rejected', 'pending', 'review_pending', 'finished', 'partial_finished']
      let filters = {
        'finished': [],
        'partial_finished': [],
        'pending': [],
        'rejected': [],
        'review_pending': []
      }
      missions.forEach(mission => {
        if (statusses.includes(mission.status)) {
          filters[mission.status].push(mission)
        }
      })
      return filters
    },
    totalMissionByStatus (allUserFilter) {
      const sumStatus = {
        'finished': 0,
        'partial_finished': 0,
        'pending': 0,
        'rejected': 0,
        'review_pending': 0
      }

      allUserFilter.forEach((user) => {
        Object.entries(user.filter).forEach((value) => {
          sumStatus[value[0]] = sumStatus[value[0]] + (value[1]).length
        })
      })

      return sumStatus
    },
    detailMissions (missions) {
      missions = missions.filter(mission => mission.status !== 'pending')
      this.missionsAndExecutions = missions
      this.showMissions = true
      console.log(missions)
    },
    loadExecutions (item) {
      this.executionData = null
      this.dataMission = item.executions
      this.missionStatus = item.status
      this.target = item.target
      const { validation_params } =  this.item
      const timePerMission = validation_params.find(params => params.parameterKey === 'hours_per_execution')
      if (timePerMission !== undefined) {
        this.dateNext = format(addHours(addHours(new Date(item.created_at), -5), parseInt(timePerMission.value)), 'PPPP', { locale: es })
      }
      this.dateStart = format(addHours(new Date(item.created_at), -5), 'PPPP', { locale: es })

      this.dataMission = this.dataMission.sort((a, b) => b.id - a.id)

      if (this.dataMission.length > 0) {
        this.showExecution(this.dataMission[0])
      }

    },
    cancel () {
      this.showMissions = false
      this.dataMission = []
    },
    showExecution (execution) {
      this.executionData = execution
      this.executionData.metadata.answers = this.executionData.answers
      this.executionView = true
    },
    async updateStatusMission (status) {
      const { id } = this.missionsAndExecutions[this.selectedMission]
      let execution = this.executionData
      switch (status) {
        case 'rejected':
          execution.finisher = false
          execution.indicator = 0
          execution.current_execution = null
          this.rejected = true
          await this.$admin.http.put(`/executions/${execution.id}/admin`, {
            execution,
            mission: {
              id,
              status: 'rejected',
              reasonMessage: this.message
            }
          }, {headers: { 'Content-Type': 'application/json' }})
          await this.missionGroupByUser()
          this.rejected = false
          this.changeStatus = false
          break
        case 'finished':
          execution.finisher = true
          execution.indicator = 1
          this.approved = true
          await this.$admin.http.put(`/executions/${execution.id}/admin`, {
            execution,
            mission: {
              id,
              status: 'approved'
            }
          }, {headers: { 'Content-Type': 'application/json' }})
          this.approved = false
          await this.missionGroupByUser()
          this.showMissions = false
          break
      }
    }
  },
  filters: {
    pretty: (val, indent = 2) => {
      if (typeof val === "string") {
        val = JSON.parse(val)
      }
      return JSON.stringify(val, null, indent)
    }
  }
};
</script>
<style>
pre {
  max-width: 100px;
}
</style>
