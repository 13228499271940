<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card elevation="1">
          <v-card-text>
            <va-show :item="item">
              <v-container>
                <v-row>
                  <v-col>
                    <va-field source="name"></va-field>
                    <va-field source="email"></va-field>
                    <va-field source="document" />
                    <va-field source="roles" />
                    <va-field source="birthday" />
                    <va-field source="gender" />
                    <va-field source="phone" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn color="blue darken-1" @click="pointsModal = true">
                    Añadir Puntos
                  </v-btn>
                  <v-btn class="ml-5" color="orange darken-1" @click="targetsModal = true">
                    Añadir Targets
                  </v-btn>
                  <v-btn class="ml-5" color="green darken-1" @click="showTargets = true">
                    Ver Targets
                  </v-btn>
                </v-row>
                <v-row>
                  <v-col>
                    <h3>Transacciones</h3>
                    <v-divider />
                    <v-simple-table v-if="transactionsList.length">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Descripción</th>
                            <th class="text-left">Monto</th>
                            <th class="text-left">Monto Final</th>
                            <th class="text-left">Fecha</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in transactionsList" :key="item.id">
                            <td>{{ item.details }}</td>
                            <td>{{ item.transaction }}</td>
                            <td>{{ item.total }}</td>
                            <td>
                              {{
                                new Date(item.created_at).toLocaleString(
                                  "sv-SE"
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>
            </va-show>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="pointsModal" scrollable max-width="600px">
        <v-card>
          <v-card-title>Añadir puntos</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 500px">
            <v-select
              label="Tipo de puntos"
              :items="items"
              v-model="currencyType"
              item-text="text"
              item-value="value"
            />
            <v-text-field type="number" v-model="pointsToGive" label="Puntos" />
            <v-text-field v-model="transDetail" label="Descripción" />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="givePoints">
              Enviar
            </v-btn>
            <v-btn color="red darken-1" text @click="pointsModal = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="targetsModal" scrollable max-width="600px">
        <v-card>
          <v-card-title>Añadir Targets</v-card-title>
          <v-divider />
          <v-card-text style="height: 500px">
            <div class="d-flex flex-column align-center justify-center" v-if="clients.length === 0">
              <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
              />
              <p>Cargando clientes ...</p>
            </div>
            <div v-else>
              <v-select v-model='client' :items=clients item-text="company_name" item-value="id" label='Clientes' @change="loadTargets"/>
              <v-progress-linear
                v-if="loadTarget"
                indeterminate
                color="yellow darken-2"
              />
              <v-autocomplete v-if="targets.length > 0" v-model='target' :items=targets chips item-text="nameShow" item-value="id" label='Targets' multiple />
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn color="blue darken-1" text :loading="saveTarget" @click="saveTargets">
              Guardar
            </v-btn>
            <v-btn color="red darken-1" text @click="targetsModal = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showTargets" scrollable width="80%" max-width="1200px">
        <v-card>
          <v-card-title>Targets</v-card-title>
          <v-divider />
          <v-card-text style="height: 500px">
            <v-data-table
              :headers="headers"
              :items="desserts"
              class="elevation-1"
              :loading="desserts.length === 0"
              loading-text="Loading... Please wait"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="mr-2"
                  @click="showEditTarget(item.target)"
                  color="blue"
                  x-small
                >
                  Editar
                </v-btn>
                <v-btn
                  x-small
                  color="red"
                  class="ml-4"
                  @click="updateTargets(item.id, !item.enabled)"
                >
                  {{!item.enabled ? 'Activar' : 'Desactivar'}}
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn color="red darken-1" text @click="showTargets = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="edit" scrollable width="60%">
      <v-card>
        <v-card-title>Target {{targetEdit.id}}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="targetEdit.name"
                label="Nombre"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="targetEdit.address"
                label="Dirección"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="targetEdit.city"
                label="Ciudad"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="targetEdit.latitude"
                label="Latitud"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="targetEdit.longitude"
                label="Longitud"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="edit = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="updateTargetNew"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      transactionsList: { length: 0 },
      pointsModal: false,
      targetsModal: false,
      pointsToGive: 0,
      transDetail: "",
      currencyType: "",
      items: [
        { value: "local", text: "Dinero" },
        { value: "loyalty", text: "Lealtad" },
      ],
      clients: [],
      client: null,
      targets: [],
      target: null,
      loadTarget: false,
      saveTarget: false,
      editTarget: false,
      showTargets: false,
      updateTarget: false,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nombre', value: 'target.name' },
        { text: 'Latitud', value: 'target.latitude' },
        { text: 'Longitud', value: 'target.longitude' },
        { text: 'Ciudad', value: 'target.city' },
        { text: 'Dirección', value: 'target.address' },
        { text: 'enabled', value: 'enabled' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      desserts: [],
      edit: false,
      enable: false,
      targetEdit: {}
    };
  },
  watch: {
    showTargets: async function (val) {
      if (val) {
        await this.loadUserTargets()
      }
    }
  },
  mounted() {
    this.showPoints();
    this.loadClients()

  },
  methods: {
    async showPoints() {
      try {
        const transactions = await this.$admin.bankProvider.getPoints(
          this.item
        );
        this.transactionsList = transactions.data.data;
        // show modal
      } catch ({ response }) {
        this.error(response.data.message);
      }
    },
    async givePoints() {
      try {
        const { uuid } = this.item;
        const transactions = await this.$admin.bankProvider.givePoints({
          user_id: uuid,
          points: this.pointsToGive,
          details: this.transDetail,
          transaction_type: "transfer",
          currency_type: this.currencyType,
        });
        console.log(transactions.data.data);
        this.pointsToGive = 0;
        this.transDetail = "";
        this.pointsModal = false;
        // show modal
      } catch ({ response }) {
        this.error(response.data.message);
      }
    },
    async loadClients () {
      const { data } = await this.$admin.http.get(`/clients`)
      this.clients = data
      // console.log(data)
    },
    async loadTargets () {
      this.loadTarget = true
      const { data } = await this.$admin.http.get(`/targets/${this.client}/client`)
      data.forEach(target => {
        const geolocation = (target.latitude !== 0 && target.longitude !== 0)
        target.nameShow = `${this.removeAccents(target.name)}-${this.removeAccents(target.city)} (geo. ${geolocation})`
      })
      this.loadTarget = false
      this.targets = data
    },
    removeAccents (str) {
      let accents = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' }
      return str.split('').map(letter => accents[letter] || letter).join('').toString()
    },
    saveTargets () {
      this.saveTarget = true
      this.$admin.geoProvider.setTargetUser({ user_id: this.$route.params.id, targets: this.target }).then(() => {
        this.saveTarget = false
      }).catch(() => {
        this.saveTarget = false
      })
    },
    updateTargets (id, enabled) {
      let self = this
      this.$admin.geoProvider.updateTargetUser({ id, enabled }).then(async () => {
        await self.loadUserTargets()
      })
    },
    async loadUserTargets () {
      const { data } = await this.$admin.http.get(`/user/targets/${this.$route.params.id}`)
      this.desserts = data
    },
    showEditTarget (target) {
      this.edit = true
      this.targetEdit = target
    },
    async updateTargetNew () {
      await this.$admin.http.put(`/targets/${this.targetEdit.id}`, this.targetEdit, {headers: { 'Content-Type': 'application/json' }})
      this.edit = false
      await this.loadUserTargets()
    }
  },
};
</script>
