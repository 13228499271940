<template>
  <va-action-button
    v-if="item.roles.includes('client')"
    :item="item"
    @click="showChallenges"
    :hide-label="icon"
    :label="$t('users.show_challenges')"
    icon="request_quote"
    color="success"
    text
  >
    <span class="material-icons">
request_quote
</span>
  </va-action-button>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ChallengesButton",
  props: {
    item: Object,
    icon: Boolean,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    showChallenges() {
      try {
        this.$router.push('challenges/'+this.item.id)
      } catch ({ ex }) {
        this.$snackbar.error(ex.message);
      }
    },
  },
};
</script>
