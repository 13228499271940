<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <segmentations-show v-if="show" :item="item"></segmentations-show>
      <segmentations-form v-else :id="id" :item="item" @saved="onSaved"></segmentations-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list ref="list" disable-create-redirect @action="onAction">
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-edit-redirect
          @item-action="onAction"
        >
          <!--template v-slot:[`item.actions`]="{ resource, item }">
          </template-->
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
// import ImpersonateButton from "@/components/buttons/ImpersonateButton";

export default {
  components: {
    // ImpersonateButton,
  },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        { source: "name", sortable: true, label:'Pregunta' },
        { source: "options", type: 'array',
          attributes: {
            select: false,
            color: "green",
            small: false,
            column: true,
            itemText:'option',
          },
        }
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
