<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col lg="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-select-input
              source="user"
              model="user"
              reference="users"
              itemValue="id"
              itemText="name"
              label="Usuario"
            />
            <va-array-input
              label="Propiedades"
              source="properties"
              v-slot="props"
            >
              <v-row>
                <v-col sm="6">
                  <va-text-input
                    v-bind="props"
                    source="property"
                    label="Propiedad"
                  />
                </v-col>
                <v-col sm="6">
                  <va-text-input v-bind="props" source="value" label="Valor" />
                </v-col>
              </v-row>
            </va-array-input>
            <va-array-input source="images" v-slot="props">
              <v-row>
                <v-col sm="12">
                  <va-file-input
                    v-bind="props"
                    :label="$t('Image')"
                    source="image"
                    required
                  />
                </v-col>
              </v-row>
            </va-array-input>
          </v-card-text>
          <va-save-button />
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item", "permissions"],
  data() {
    return {
      states: [
        { value: "added", text: "En carrito" },
        { value: "ordered", text: "Comprado" },
        { value: "returned", text: "Devuelto" },
        { value: "issued", text: "Enviado" },
        { value: "sent", text: "Entregado" },
        { value: "in progress", text: "En progreso" },
        { value: "lost", text: "Perdido" },
        { value: "dipute", text: "En disputa" },
      ],
    };
  },
};
</script>
