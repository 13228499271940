<template>
  <va-action-button
    :item="item"
    @click="showAndAddCategories"
    :hide-label="icon"
    :label="$t('clients.add_categories')"
    icon="mdi-clipboard-list"
    color="primary"
    text
  ></va-action-button>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CategoriesClientButton",
  props: {
    item: Object,
    icon: Boolean,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    async showPoints() {
      try {
        const transactions = await this.$admin.bankProvider.getPoints(this.item);
        this.$emit('answer', transactions.data.data)
        // show modal
      } catch ({ response }) {
        this.$snackbar.error(response.data.message);
      }
    },
    async showAndAddCategories () {
      try {
        const categories = await this.$admin.http.get(`/categories/${this.item.id}/client`)
        this.$emit('categories', { categories: categories.data, id: this.item.id })
        // show modal
      } catch ({ response }) {
        this.$snackbar.error(response.data.message);
      }
    }
  },
};
</script>
