<template>
  <div>
    <v-dialog v-model="show" max-width="90%" @click:outside="cancel">
      <RewardsList :is-modal="true" @closeModalReward="cancel" :challenge-id="this.challengeId" :client="this.client" ></RewardsList>
    </v-dialog>
    <snack-alert :show-snack="message.show" :message="message" @closeSnack="statusSnack" />
  </div>
</template>

<script>
import SnackAlert from "../SnackAlert";
import RewardsList from "@/components/common/rewards/RewardsList";

export default {
  name: "ShowByChallenge",
  props: ['showModal', 'challengeId', 'client'],
  components: {
    RewardsList,
    SnackAlert
  },
  watch: {
    showModal: async function(val) {
      this.show = val

    }
  },
  data: () => {
    return {
      show: false,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nombre', value: 'reward.name' },
        { text: 'Activo', value: 'enabled'},
        { text: 'Bondii Coins (BC)', value: 'reward.amount_loyalty' },
        { text: 'Dinero Local ($)', value: 'reward.amount_money' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      rewardsByChallenge: [],
      load: false,
      rewardsClient: [],
      openRewardChallenge: false,
      saveRelation: false,
      message: {
        show: false,
        timeout: 2000,
        text: 'Reward create successfully',
        type: 'success'
      },
      loadRewardsInField: false,
      listRewards: []
    }
  },
  methods: {
    cancel () {
      this.$emit('closeModalReward', { status: false })
      this.rewardsByChallenge = []
      this.load = false
    },
    enableReward (item) {
      this.$admin.http.put(`/challenge-reward/${item.id}/enabled`, { enabled: !item.enabled }, {headers: { 'Content-Type': 'application/json' }}).then(async () => {
        this.message.show = true
        this.message.type = 'success'
        this.message.text = 'Reward update successfully'
        this.saveRelation = false
        await this.rewardsByChallengeAPI()
      }).catch((error) => {
        this.message.show = true
        this.message.text = error.toString()
        this.message.type = 'error'
        this.saveRelation = false
      })
    },
    async addRewardToChallenge () {
      this.saveRelation = true
      const responses = []
      for (const reward of this.listRewards) {
        const { data } = await this.$admin.http.post(`/challenge-reward/`, {
          reward: reward,
          challenge: this.challengeId,
          enabled: true
        }, {headers: { 'Content-Type': 'application/json' }})
        if (Object.values(data).includes('error')) {
          responses.push(reward)
        }
      }

      this.message.show = true
      if (responses.length > 0) {
        this.message.text = "Error in rewards" + responses.length
        this.message.type = 'error'
      } else {
        this.openRewardChallenge = false
        this.message.type = 'success'
        this.message.text = 'Reward update successfully'
        await this.rewardsByChallengeAPI()
      }
      this.saveRelation = false

    },
    async rewardsByChallengeAPI () {
      this.load = true
      const { data } = await this.$admin.http.get(`/challenge-reward/${this.challengeId}/challenge`)
      this.rewardsByChallenge = data
      this.load = false
    },
    async rewardsByClient () {
      this.loadRewardsInField = true
      const { data } =  await this.$admin.http.get(`/rewards/${this.client}/client`)
      this.rewardsClient = data
      this.loadRewardsInField = false
    },
    statusSnack (value) {
      this.message.show = value.status
    }
  },
};
</script>

<style scoped>

</style>
