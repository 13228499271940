<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col lg="8">
        <va-text-input source="name" />
        <va-text-input source="sku" />
        <va-text-input source="description" />
        <va-number-input source="quantity" />
        <va-number-input source="price" />
        <va-number-input source="loyalty_price" />
        <va-select-input
          source="category"
          model="category"
          reference="categories"
          itemValue="id"
          itemText="name"
        />
        <va-array-input source="characteristics" v-slot="props">
          <v-row>
            <v-col sm="6">
              <va-select-input v-bind="props" :choices="types" source="type" />
            </v-col>
            <v-col sm="6">
              <va-text-input v-bind="props" source="value" />
            </v-col>
          </v-row>
        </va-array-input>
        <va-array-input source="images" v-slot="props">
          <v-row>
            <v-col sm="12">
              <va-file-input
                v-bind="props"
                :label="$t('Image')"
                source="image"
                required
              />
            </v-col>
          </v-row>
        </va-array-input>
        <va-save-button />
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      types: [
        { value: "color", text: "Color" },
        { value: "size", text: "Size" },
        { value: "gender", text: "Gender" },
        { value: "category", text: "Category" },
      ],
    };
  },
};
</script>
