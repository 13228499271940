import Vue from "vue";
import VuetifyAdmin from "@/vuetify-admin";

import "vuetify-admin/src/loader";

import {hydraDataProvider, pointsProvider, geoProvider, notificationsProvider} from "@/providers";
import { laravelDataProvider } from "vuetify-admin/src/providers";
import { jwtAuthProvider } from "@/providers";
import { en } from "vuetify-admin/src/locales";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
import axios from "axios";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

const params = {
  routes: {
    login: "token",
    logout: "logout",
    refresh: "token",
    user: "user/info",
  },
  getCredentials: ({ username, password }) => {
    return {
      username,
      password,
      client_secret: "905d9623114dbe52d787202e4e7f2253",
      client_id: "0f513840-2a15-45d9-8b7b-3ddd4f6e9567",
      grant_type: "password",
    };
  },
};

let baseURL = process.env.VUE_APP_AUTH_URL;
const authHttp = axios.create({
  baseURL,
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

// const jwtAuth = jwtAuthProvider(authHttp, params);
let token = localStorage.getItem("jwt_token");
// token = jwtAuth.getToken(token);
baseURL = process.env.VUE_APP_API_URL || "http://bondii.test/api";
const http = axios.create({
  baseURL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Authorization: token,
    "Content-Type": "multipart/form-data",
  },
});

baseURL = process.env.VUE_APP_MARKET_API_URL || "http://market.bondii.test";
const httpMarket = axios.create({
  baseURL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Authorization: token,
    "Content-Type": "multipart/form-data",
  },
});

baseURL = process.env.VUE_APP_BANK_API_URL || "http://bank.bondii.test";
const httpBank = axios.create({
  baseURL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Authorization: token,
    "Content-Type": "multipart/form-data",
  },
});

baseURL = process.env.VUE_APP_GEO_API_URL || "http://geo.bondii.test";
const httpGeo = axios.create({
  baseURL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Authorization: token,
    "Content-Type": "multipart/form-data",
  },
});


baseURL = process.env.VUE_APP_NOTIFICATIONS || "https://notification.bondii.net";
const httpNotifications = axios.create({
  baseURL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Authorization: token,
    "Content-Type": "multipart/form-data",
  },
});

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "Bondii",
  routes,
  locales: {
    en,
  },
  translations: ["en"],
  dataProvider: hydraDataProvider(http),
  marketProvider: laravelDataProvider(httpMarket),
  bankProvider: pointsProvider(httpBank),
  geoProvider: geoProvider(httpGeo),
  notificationsProvider: notificationsProvider(httpNotifications),
  authProvider: jwtAuthProvider(authHttp, params),
  resources,
  httpNotifications,
  http,
  options: {
    dateFormat: "short",
  },
});
