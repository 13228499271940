<template>
  <div>
    <base-material-card :icon="resource.icon" :title="title" >
      <va-list ref="list">
        <va-data-table :fields="fields" disableDelete disable-clone>

        </va-data-table>
      </va-list>
    </base-material-card>
    <v-row justify="center">
      <v-dialog
          v-model="targetModal"
          max-width="60vw"
          scrollable
      >
        <create-target :targets="filterItems" :currentTargets = this.currentTargets :challengeId="this.idChallenge" :client_id="this.clientId"  @closeTargetModal="closeTargetModal"></create-target>

      </v-dialog>
    </v-row>
    <v-snackbar
        v-model="targetSave"
    >
      Los targets han sido almacenados

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="targetSave = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <show-by-challenge
        :challenge-id="rewardChallenge.challenge"
        :client="rewardChallenge.client"
        :show-modal="rewardChallenge.show"
        @closeModalReward="closeModalReward"
    />
    <show-questions
        :challenge-id="questionChallenge.challenge"
        :show-modal="questionChallenge.show"
        @closeModalQuestion="closeModalQuestion"
    />
  </div>
</template>

<script>

import ShowByChallenge from "../../components/common/rewards/ShowByChallenge";
import ShowQuestions from "../../components/common/questions/ShowByChallenge";
import CreateTarget from "../../components/common/targets/CreateTarget"

export default {
  props: ["resource", "title"],
  components: {
    ShowByChallenge,
    ShowQuestions,
    CreateTarget
  },
  data() {
    return {
      fields: [
        {source: "id", sortable: true},
        {source: "name", sortable: true},
        {source: "points_to_win"},
        {source: "loyal_points_to_win"},
        {source: "created_at", type: "date"},
      ],
      targetList: [],
      targetModal: false,
      clientId: 0,
      items: [],
      model: [],
      allItems: true,
      filterItems: [],
      target: '',
      currentTargets: [],
      idChallenge: null,
      load: false,
      targetSave: false,
      rewardChallenge: {
        show: false,
        challenge: null,
        client: null
      },
      questionChallenge: {
        show: false,
        challenge: null
      }
    }
  },
  methods: {


    async showTargets(targets) {


      this.idChallenge = targets.challenge.id
      this.clientId = targets.challenge.client.id
      this.items = targets.targets
      this.filterItems = targets.targets
      const { data } = await this.$admin.http.get(`/challenges/${targets.challenge.id}`)

      this.currentTargets = data.targets
      this.selectAll()

      this.targetModal = true
    },
    closeTargetModal(status) {
      this.targetModal = status;
    },
    selectAll() {
      if (this.allItems) {
        this.filterItems.forEach(item => {
          this.model.push(item.id)
        })
      } else {
        this.model = []
      }
    },
    searchTarget() {
      if (this.target.length > 0) {
        this.allItems = false
      }
      this.filterItems = this.items.filter(i => {
        return i.name.toLowerCase().includes(this.target.toLowerCase())
      });
    },
    async showMissions(missions) {
      console.log(missions)
    },
    openRewardsByChallenge(reward) {
      this.rewardChallenge = {
        show: true,
        challenge: reward.challenge,
        client: reward.client
      }
    },
    openQuestionByChallenge(reward) {
      this.questionChallenge = {
        show: true,
        challenge: reward.challenge
      }
    },
    closeModalQuestion(value) {
      this.questionChallenge = {
        show: value.status,
        challenge: null,
      }
    },
    closeModalReward(value) {
      this.rewardChallenge = {
        show: value.status,
        challenge: null,
        client: null
      }
    }
  }
};
</script>
