<template>
  <div>
    <base-material-card :icon="resource.icon" :title="title">
      <v-data-table :headers="fields" :items="jsonData" />
    </base-material-card>
  </div>
</template>

<script>

export default {
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        { text: 'id', value: 'id' },
        { text: 'Reto Id', value: 'challenge_id' },
        { text: 'Tìtulo', value: 'title' },
        { text: 'Mensaje', value: 'message' },
        { text: 'Type', value: 'type' },
        { text: 'Creación', value: 'created_at' },
      ],
      jsonData: [
        {
          "id": 9,
          "challenge_id": 12,
          "title": "Bienvenido",
          "message": "Hola Bondii, Bienvenido al programa",
          "type": "once",
          "configuration": {"hour": "*", "minute": "*", "dayOfWeek": "*", "dayOfMonth": "*"},
          "is_static": 1,
          "is_active": 0,
          "created_at": "2023-04-28 10:43:21",
          "updated_at": "2023-04-28 10:43:21"
        },
        {
          "id": 10,
          "challenge_id": 12,
          "title": "Bienvenido Agente",
          "message": "¿Estás listo para ganar dinero en tu tiempo libre?",
          "type": "monthly",
          "configuration": {"hour": "18", "minute": "0", "dayOfWeek": "*", "dayOfMonth": "29"},
          "is_static": 1,
          "is_active": 0,
          "created_at": "2023-04-29 12:08:47",
          "updated_at": "2023-04-29 12:08:47"
        },
        {
          "id": 11,
          "challenge_id": 17,
          "title": "¡Felicitaciones, hay una misión disponible para ti!",
          "message": "¡Tienes una nueva misión. Si decides aceptarla!",
          "type": "once",
          "configuration": {"hour": "*", "minute": "*", "dayOfWeek": "*", "dayOfMonth": "*"},
          "is_static": 1,
          "is_active": 0,
          "created_at": "2023-05-03 15:25:43",
          "updated_at": "2023-05-03 15:25:43"
        },
        {
          "id": 12,
          "challenge_id": 24,
          "title": "¡Felicitaciones, hay una misión disponible para ti!",
          "message": "¡Tienes una nueva mision. Si decides aceptarla!",
          "type": "once",
          "configuration": {"hour": "*", "minute": "*", "dayOfWeek": "*", "dayOfMonth": "*"},
          "is_static": 1,
          "is_active": 0,
          "created_at": "2023-05-12 15:14:07",
          "updated_at": "2023-05-12 15:14:07"
        },
        {
          "id": 13,
          "challenge_id": 26,
          "title": "¡Felicitaciones, hay una misión disponible para ti!",
          "message": "¡Tienes una nueva misión. Si decides aceptarla!",
          "type": "once",
          "configuration": {"hour": "*", "minute": "*", "dayOfWeek": "*", "dayOfMonth": "*"},
          "is_static": 1,
          "is_active": 0,
          "created_at": "2023-05-24 13:54:45",
          "updated_at": "2023-05-24 13:54:45"
        },
        {
          "id": 14,
          "challenge_id": 54,
          "title": "Felicitaciones, hay una mision disponible para ti",
          "message": "hay una nueva mision  si deseas aceptarla.",
          "type": "once",
          "configuration": {"hour": "*", "minute": "*", "dayOfWeek": "*", "dayOfMonth": "*"},
          "is_static": 1,
          "is_active": 0,
          "created_at": "2023-08-30 22:52:13",
          "updated_at": "2023-08-30 22:52:13"
        },
        {
          "id": 15,
          "challenge_id": 54,
          "title": "¡Felicitaciones, hay una misión disponible para ti!",
          "message": "¡Tienes una nueva mision. Si decides aceptarla!",
          "type": "once",
          "configuration": {"hour": "*", "minute": "*", "dayOfWeek": "*", "dayOfMonth": "*"},
          "is_static": 1,
          "is_active": 0,
          "created_at": "2023-08-30 22:55:00",
          "updated_at": "2023-08-30 22:55:00"
        },
        {
          "id": 16,
          "challenge_id": 54,
          "title": "¡Felicitaciones, hay una misión disponible para ti!",
          "message": "¡Tienes una nueva mision. Si decides aceptarla!",
          "type": "daily",
          "configuration": {"hour": "22", "minute": "58", "dayOfWeek": "*", "dayOfMonth": "*"},
          "is_static": 1,
          "is_active": 0,
          "created_at": "2023-08-30 22:57:23",
          "updated_at": "2023-08-30 22:57:23"
        }
      ], // Aquí almacenaremos los datos del archivo JSON
    };
  },
  mounted() {
  },
};
</script>
