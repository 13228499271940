<template>
  <v-card>
    <v-card-title class="text-left display-2 font-weight-bold pb-2">Targets del cliente para la misión</v-card-title>
    <v-divider/>
    <v-card-text>
      <v-row>
        <v-col cols="5">
          <div class="pl-4">
            <v-text-field
                v-model="target"
                label="Busca un target aquí"
                placeholder="Exito la 90"
                @input="searchTarget"
            />
          </div>
          <v-list class="overflow-auto" height="65vh">
            <v-list-item-group v-model="model" multiple>
              <v-list-item active-class="deep-purple--text text--accent-4">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-text="'SELECCIONAR TODOS'"/>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox
                        v-model="allItems"
                        color="deep-purple accent-4"
                        @change="selectAll()"
                    ></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
              <template v-for="(item, i) in clientTargets">
                <v-list-item
                    :key="i"
                    :value="item.id"
                    active-class="deep-purple--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium" v-text="item.name"/>
                      <v-list-item-subtitle class="caption">{{ item.address }} - {{ item.city }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                          :input-value=" active"
                          color="deep-purple accent-4"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
          <v-btn  small :disabled="load" :loading="load" color="success" @click="setTarget" class="mt-4">
            Añadir targets a la misión
          </v-btn>
        </v-col>
        <v-divider vertical/>
        <v-col class="px-6" cols="7">
          <p class="display-1 font-weight-bold">Crear un nuevo Target</p>
          <v-text-field
              v-model="targetCreate.name"
              label="Nombre del target"
              placeholder="Don jacinto"
              required
          />
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="targetCreate.latitude"
                  label="Latitud"
                  placeholder="4.23123123"
                  required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="targetCreate.longitude"
                  label="Longitud"
                  placeholder="-75.3241"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="targetCreate.regional"
                  label="Regional"
                  placeholder="ALTILLANOS"
                  required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="targetCreate.city"
                  label="Ciudad"
                  placeholder="Bogotá"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="targetCreate.address"
                  label="Dirección"
                  placeholder="Cll 57c 96-93"
                  required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="targetCreate.district"
                  label="Barrio"
                  placeholder="Juan XX"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="targetCreate.channel"
                  label="Canal"
                  placeholder="Autoservicios"
                  required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="targetCreate.subChannel"
                  label="SubCanal"
                  placeholder="cadenas"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="targetCreate.typology"
                  label="Tipología"
                  placeholder="Superetes"
                  required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="targetCreate.external_id"
                  label="ID Externo"
                  placeholder="504301"
                  required
              />
            </v-col>
          </v-row>
          <v-row class="px-4">
            <v-btn block class="success" @click="createTarget">Guardar Target</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-if="this.isModal">
      <v-spacer></v-spacer>
      <v-btn  :loading="load" color="blue darken-1" text @click="$emit('closeTargetModal',false)">
        Cerrar
      </v-btn>
      <v-btn v-show="true" @click="getTargets"> get targets</v-btn>
    </v-card-actions>
    <snack-alert :message="message" :show-snack="message.show" @closeSnack="statusSnack"/>
  </v-card>
</template>

<script>
import SnackAlert from "@/components/common/SnackAlert.vue";

export default {
  name: "CreateTarget",
  components: {SnackAlert},
  props: {targets: Array, challengeId: Number, client_id: Number, currentTargets: Array, isModal: Boolean},
  data() {
    return {
      message: {
        show: false,
        timeout: 2000,
        text: 'Target created/assigned',
        type: 'success'
      },

      filterItems: [],
      model: [],
      load: false,
      target: '',
      clientTargets:[],
      allItems: false,
      targetCreate: {
        name: '',
        latitude: '',
        longitude: '',
        regional: '',
        city: '',
        channel: '',
        subChannel: '',
        address: '',
        district: '',
        typology: '',
        external_id: '',
        client: this.client_id,
      }
    }

  },

  methods: {

    statusSnack(value) {
      this.message.show = value.status
    },
    searchTarget() {
      if (this.target.length > 0) {
        this.allItems = false
      }
      this.filterItems = this.targets.filter(i => {
        return i.name.toLowerCase().includes(this.target.toLowerCase())
      });
    },
    async createTarget() {

      // this.targetCreate.client_id = this.client_id;
      console.log(this.targetCreate)
      this.load = true;
      try {

        const targets = await this.$admin.http.post(`/targets`, this.targetCreate, {headers: {'Content-Type': 'application/json'}})
        console.log(targets)

        // show modal
        this.message.show = true
        this.message.text = 'Target creado con éxito'
        this.message.type = 'success'
      } catch (e) {

         this.message.show = true
        this.message.text = `Error, ${e}`
        this.message.type = 'error'

      }

      await this.getTargets();
      this.load = false;
    },
    async setTarget() {
      this.load = true
      const index = this.model.indexOf(0)
      if (index > -1) { // only splice array when item is found
        this.model.splice(index, 1); // 2nd parameter means remove one item only
      }
      try {
        await this.$admin.geoProvider.setTarget({challenge_id: this.challengeId, targets: this.model})


        this.message.show = true
        this.message.text = 'Target/s asignado/s con éxito'
        this.message.type = 'success'
      }catch (e){
        this.message.show = true
        this.message.text = `Error, ${e}`
        this.message.type = 'error'
      }
      this.load = false
    },
    selectAll() {
      if (this.allItems) {
        this.targets.forEach(item => {
          this.model.push(item.id)
        })
      } else {
        this.model = []
      }
    },

    async getTargets(){
      this.load = true
      const {data} = await this.$admin.http.get(`/targets/${this.client_id}/client`);
      this.clientTargets = data;
      this.load =false
      console.log(this.clientTargets)

    }
  },
  watch: {
    'this.targets': (oldTargets, newTargets) => {
      console.log(oldTargets)
      console.log(newTargets  )
      this.currentTargets.forEach((currentTarget) => {
        const currentTargetId = currentTarget.id
        const targetAdd = this.targets.find((target) => target.id === currentTargetId)
        if (targetAdd) {
          this.model.push(targetAdd.id)
        }
      })
      console.log(this.currentTargets)
      console.log(this.targets)
    },
    currentTargets: (value) => {
      this.currentTargets = value;
      this.currentTargets.forEach((currentTarget) => {
        const currentTargetId = currentTarget.id
        const targetAdd = this.targets.find((target) => target.id === currentTargetId)
        if (targetAdd) {
          this.model.push(targetAdd.id)
        }
      })
      console.log(this.currentTargets)
      console.log(this.targets)
    }
  },
  mounted() {
    this.getTargets();
  },
  beforeMount() {
    console.log('beforeMount')
    console.log(this.clientTargets)

    this.targetModal = true;
if(this.currentTargets) {
  this.currentTargets.forEach((currentTarget) => {
    const currentTargetId = currentTarget.id
    const targetAdd = this.targets.find((target) => target.id === currentTargetId)
    if (targetAdd) {
      this.model.push(targetAdd.id)
    }
  });
  console.log(this.currentTargets)
  console.log(this.targets)
}

  }

}
</script>

<style scoped>

</style>