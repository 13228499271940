<template>
  <div>
    <v-snackbar
      :color="color"
      v-model="show"
      :timeout="message.timeout"
      @input="closeSnack"
    >
      {{ message.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="closeSnack"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "SnackAlert",
  props: ['showSnack', 'message'],
  data: () => {
    return {
      show: false,
      color: 'grey darken-1'
    }
  },
  watch: {
    showSnack: function (val) {
      if (val) {
        this.show = val
        this.color = this.selectColor()
      }
    }
  },
  mounted() {},
  methods: {
    selectColor () {
      const type = this.message.type
      switch (type) {
        case 'success':
          return 'success'
        case 'error':
          return 'red'
        default:
          return 'grey darken-1'
      }
    },
    closeSnack() {
      this.show = false
      this.$emit('closeSnack', { status: false })
    }
  }
};
</script>

<style scoped>

</style>
