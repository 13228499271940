<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card elevation="1">
          <v-card-text>
            <va-show :item="item">
              <v-container>
                <v-row>
                  <v-col>
                    <va-field source="company_name"/>
                    <va-field source="user.email"/>
                    <va-field source="user.document" />
                    <va-field source="user.roles" />
                    <va-field source="user.phone" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn color="primary" @click="addRewards = true">
                    Añadir Recompensas
                  </v-btn>
                  <v-btn class="ml-5" color="green darken-1" @click="showRewards = true">
                    Ver Recompensas
                  </v-btn>
                </v-row>
              </v-container>
            </va-show>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <show-modal :show="showRewards" :client="item.id" @cancel="closeCancel" />
    <create-modal :show="addRewards" :client="item.id" :edit="null" @cancel="closeCancel" />
  </v-container>
</template>

<script>
import CreateModal from "../../components/common/rewards/CreateModal";
import ShowModal from "../../components/common/rewards/ShowModal";

export default {
  props: ["item"],
  components: {
    CreateModal,
    ShowModal
  },
  data() {
    return {
      addRewards: false,
      showRewards: false
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    closeCancel ({ modal, status }) {
      if (modal === 'show') {
        this.showRewards = status
      } else if (modal === 'create') {
        this.addRewards = status
      }
    }
  },
};
</script>
