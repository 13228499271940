<template>
  <div>
    <v-dialog v-model="openModal" max-width="900px">
      <v-card>
        <v-card-title class="font-weight-bold">Recompensas creadas</v-card-title>
        <v-card-text>
          <v-data-table
            items-per-page="5"
            :headers="headers"
            :items="rewards"
            :loading="loadRewards"
            loading-text="Espera por favor ..."
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editReward(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="red" @click="close">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <create-modal :show="rewardUpdate" :client="client" :edit="reward" @cancel="cancel" />
  </div>
</template>

<script>
import CreateModal from "./CreateModal";

export default {
  name: "ShowModal",
  props: ['show', 'client'],
  components: {
    CreateModal
  },
  watch: {
    show: async function(val) {
      this.openModal = val
      await this.rewardsAPI()
    }
  },
  data: () => {
    return {
      openModal: false,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nombre', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Tipo', value: 'type' },
        { text: 'Bondii Coins (BC)', value: 'amount_loyalty' },
        { text: 'Dinero Local ($)', value: 'amount_money' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      loadRewards: false,
      rewards: [],
      rewardUpdate: false,
      reward: null
    }
  },
  methods: {
    close () {
      this.$emit('cancel', { modal: 'show', status: false })
    },
    async rewardsAPI () {
      this.loadRewards = true
      const { data } =  await this.$admin.http.get(`/rewards/${this.client}/client`)
      this.rewards = data
      this.loadRewards = false
    },
    editReward (reward) {
      this.rewardUpdate = true
      this.reward = reward
    },
    async cancel(value) {
      if (value.modal === 'update') await this.rewardsAPI()
      this.rewardUpdate = false
    }
  }
};
</script>

<style scoped>

</style>
