<template>
  <div>
    <v-dialog v-model="openModal" max-width="500px"  @click:outside="close">
     <create-form :edit="edit" :rewardEdit="reward" :client="client" :isModal="true" @cancel="close">
     </create-form>
    </v-dialog>
    <snack-alert :show-snack="message.show" :message="message" @closeSnack="statusSnack"/>
  </div>
</template>

<script>
import SnackAlert from "../SnackAlert";
import CreateForm from "@/components/common/rewards/CreateForm.vue";

export default {
  name: "CreateModal",
  props: ['show', 'client', 'edit'],
  components: {
    CreateForm,
    SnackAlert
  },
  watch: {
    show: function (val) {
      this.openModal = val
      if (this.edit !== null) {
        this.reward = {
          client: this.client,
          name: this.edit.name,
          description: this.edit.description,
          type: this.edit.type,
          amount_loyalty: this.edit.amount_loyalty,
          amount_money: this.edit.amount_money,
          id: this.edit.id
        }
      }
    }
  },
  data: () => {
    return {
      openModal: false,
      types: [
        { key: 'exhibition', text: 'Exhibición' },
        { key: 'capture', text: 'Captura' },
        { key: 'invoices', text: 'Facturas' }
      ],
      reward: {
        client: null,
        name: '',
        description: '',
        type: 'exhibition',
        amount_loyalty: 0,
        amount_money: 0
      },
      message: {
        show: false,
        timeout: 2000,
        text: 'Reward create successfully',
        type: 'success'
      },
      saveTo: false
    }
  },
  mounted() {},
  methods: {
    statusSnack (value) {
      this.message.show = value.status
    },
    close () {
      this.$emit('cancel', { modal: 'create', status: false })
    },
    rewardAction() {
      this.saveTo = true
      this.reward.client = this.client
      this.$admin.http.post(`/rewards/`, this.reward, {headers: { 'Content-Type': 'application/json' }}).then(() => {
        this.message.show = true
        this.message.type = 'success'
        this.message.text = 'Reward create successfully'
        this.saveTo = false
        this.$emit('cancel', { modal: 'create', status: false })
      }).catch((error) => {
        this.message.show = true
        this.message.text = error.toString()
        this.message.type = 'error'
        this.saveTo = false
      })
    },
    updateReward () {
      this.saveTo = true
      this.reward.client = this.client
      this.$admin.http.put(`/rewards/${this.reward.id}`, this.reward, {headers: { 'Content-Type': 'application/json' }}).then(() => {
        this.$emit('cancel', { modal: 'update', status: false })
        this.message.show = true
        this.message.text = 'Reward update :D'
        this.message.type = 'primary'
        this.saveTo = false
      }).catch((error) => {
        this.message.show = true
        this.message.text = error.toString()
        this.message.type = 'error'
        this.saveTo = false
      })
    }
  }
};
</script>

<style scoped>

</style>
