<template>
  <div>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list ref="list">
        <va-data-table :fields="fields">
          <template v-slot:[`item.actions`]="{ resource, item }">
            <categories-client-button :resource="resource" :item="item" icon @categories="showCategories"/>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
    <v-row justify="center">
      <v-dialog
        v-model="categoriesModal"
        scrollable
        max-width="60vw"
      >
        <v-card>
          <v-card-title>Categorías</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row style="height: 400px;">
              <v-col md="4" class="overflow-auto">
                <div class="d-flex flex-column">
                  <p class="display-2">Categorías del usuario</p>
                  <v-divider/>
                  <v-list link>
                    <v-list-item-group
                      v-model="selectCategory"
                      color="primary"
                    >
                      <v-list-item
                        v-for="(cL, cLIndex) in categoriesList"
                        :key="cLIndex"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="cL.name"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col md="8">
                <div class="d-flex flex-column">
                  <p class="display-2 ">Agregar categorías</p>
                  <v-divider />
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="category.name"
                        :counter="32"
                        label="Nombre de la categoría"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="category.ia_class_name"
                        :counter="32"
                        label="Clase de la IA"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="category.family"
                        :items="family"
                        label="Familia"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-btn
                        :loading="load"
                        :disabled="load"
                        color="blue-grey"
                        class="ma-2 white--text"
                        @click="saveCategory"
                        block
                      >
                        Guardar categoría
                        <v-icon
                          right
                          dark
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="categoriesModal = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import CategoriesClientButton from "../../components/buttons/CategoriesClientButton";

export default {
  components: {
    CategoriesClientButton
  },
  props: ["resource", "title", "item"],
  data() {
    return {
      fields: [
        { source: "company_name", sortable: true },
        { source: "user.document",  label: 'NIT'},
        { source: "user.email", label: 'Correo', type: 'email'},
        { source: "user.phone", label: 'Celular', type: 'phone'},
      ],
      categoriesModal: false,
      categoriesList: [],
      selectCategory: 0,
      family: ['Bebidas', 'Comida', 'Servicio', 'Dulces', 'Imagenes'],
      load: false,
      category: {
        "name": "",
        "ia_class_index": 0,
        "ia_class_name": "",
        "family": ""
      },
      userId: '',
      rewards: [],
      rewardShow: false
    };
  },
  mounted() {
  },
  methods: {
    showCategories (categories) {
      this.categoriesList = categories.categories
      this.categoriesModal = true
      this.userId = categories.id
    },
    async saveCategory () {
      this.load = true
      this.category.client = this.userId
      const categories = await this.$admin.http.post(`/categories/`, this.category, {headers: { 'Content-Type': 'application/json' }})
      const { data } = categories
      this.categoriesList.push(data)
      this.load = false
    }
  }
};
</script>
