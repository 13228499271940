<template>
  <v-card>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="notificationsByChallenge"
          :loading="load"
          loading-text="Espera por favor ..."
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="font-weight-bold">Notificaciones del reto</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="enableNotification(item)"
              :color="[null, false, 0].includes(item.is_active) ? 'red' : 'green'"
          >
            {{ [null, false, 0].includes(item.is_active) ? 'mdi-close' : 'mdi-check' }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="this.is-modal">
      <v-btn text color="primary" @click="cancel">Cerrar</v-btn>
    </v-card-actions>
    <snack-alert :show-snack="message.show" :message="message" @closeSnack="statusSnack" />
  </v-card>
</template>

<script>
import SnackAlert from "@/components/common/SnackAlert";

export default {
  name: "NotificationList",
  props: [ 'challengeId', 'is-modal'],
  components: {
    SnackAlert
  },
  data: () => {
    return {
      show: false,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Titulo', value: 'title' },
        { text: 'Mensaje', value: 'message'},
        { text: 'Hora', value: 'configuration.hour'},
        { text: 'Minuto', value: 'configuration.minute'},
        { text: 'Dia de la semana', value: 'configuration.dayOfWeek'},
        { text: 'Dia del mes', value: 'configuration.dayOfMonth'},
        { text: 'Periodicidad', value: 'type'},
        { text: 'Activa', value: 'actions', sortable: false }
      ],
      notificationsByChallenge: [],
      load: false,
      questions: [],
      openRewardChallenge: false,
      saveRelation: false,
      message: {
        show: false,
        timeout: 2000,
        text: 'Notificación activada',
        type: 'success'
      },
      loadQuestionsInField: false,
      listQuestions: []
    }
  },
  mounted() {
    this.notificationsByChallengeAPI();
  },
  methods: {
    cancel () {
      this.$emit('closeModalQuestion', { status: false })
      this.notificationsByChallenge = []
      this.load = false
    },

    enableNotification (item) {
      const active = Boolean(item.is_active)
      this.$admin.httpNotifications.post(`/active-notification`, {
        challengeNotificationId: item.id,
        active: !active
      }, {headers: { 'Content-Type': 'application/json' }}).then(async () => {
        this.message.show = true
        this.message.type = 'success'
        this.message.text = `Notificación ${!active? "activada": "desactivada"  } `
        this.saveRelation = false
        await this.notificationsByChallengeAPI()
      }).catch((error) => {
        this.message.show = true
        this.message.text = error.toString()
        this.message.type = 'error'
        this.saveRelation = false
      })
    },
    async notificationsByChallengeAPI () {
      this.load = true
      const { data: { content }} = await this.$admin.httpNotifications.get(`/notifications/${this.challengeId}`)
      this.notificationsByChallenge = content
      this.load = false

    },
    statusSnack (value) {
      this.message.show = value.status
    }
  },
}
</script>

<style scoped>

</style>