<template>
  <div>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list ref="list">
        <va-data-table :fields="fields">
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>

export default {
  components: {},
  props: ["resource", "title", "item"],
  data() {
    return {
      fields: [
        { source: "question", label: 'Pregunta', sortable: true },
        { source: "multiple", label: 'Multiple', sortable: true },
        { source: "answers",  label: 'Respuestas'},
        { source: "position", label: 'Posición'},
        { source: "type", label: 'Tipo de la respuesta'},
        { source: "enabled", label: 'Habilitada'},
      ],
    };
  },
  mounted() {},
  methods: {}
};
</script>
