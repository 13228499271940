<template>
  <va-edit-layout :title="title">
    <catalogs-form :item="item" :id="id" :productList="productList" />
  </va-edit-layout>
</template>

<script>
import axios from "axios";


export default {
  props: ["id", "title", "item"],
  data(){
    return {
      productList: []
    }
  },
  mounted () {
    axios
        .get(process.env.VUE_APP_MARKET_API_URL+"/api/products")
        .then(

            response => (
               this.productList = response.data.data
              ))

  },

};
</script>
