<template>
  <div>
    <v-dialog v-model="show" max-width="90%" @click:outside="cancel">
      <QuestionsList :challenge-id="this.challengeId" @closeModalQuestion="cancel"/>
    </v-dialog>
    <snack-alert :show-snack="message.show" :message="message" @closeSnack="statusSnack" />
  </div>
</template>

<script>
import SnackAlert from "../SnackAlert";
import QuestionsList from "./QuestionsList"

export default {
  name: "ShowByChallenge",
  props: ['showModal', 'challengeId'],
  components: {
    SnackAlert,
    QuestionsList
  },
  watch: {
    showModal: async function(val) {
      this.show = val
      if (val) {
        await this.questionsByChallengeAPI()
      }
    }
  },
  data: () => {
    return {
      show: false,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Pregunta', value: 'question.question' },
        { text: 'Respuestas', value: 'question.answers'},
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      questionsByChallenge: [],
      load: false,
      questions: [],
      openRewardChallenge: false,
      saveRelation: false,
      message: {
        show: false,
        timeout: 2000,
        text: 'Reward create successfully',
        type: 'success'
      },
      loadQuestionsInField: false,
      listQuestions: []
    }
  },
  methods: {
    cancel () {
      this.$emit('closeModalQuestion', { status: false })
      this.questionsByChallenge = []
      this.load = false
    },

    enableQuestion (item) {
      this.$admin.http.put(`/challenge-question/${item.id}/enabled`, { enabled: !item.enabled }, {headers: { 'Content-Type': 'application/json' }}).then(async () => {
        this.message.show = true
        this.message.type = 'success'
        this.message.text = 'Reward update successfully'
        this.saveRelation = false
        await this.questionsByChallengeAPI()
      }).catch((error) => {
        this.message.show = true
        this.message.text = error.toString()
        this.message.type = 'error'
        this.saveRelation = false
      })
    },
    async addQuestionToChallenge () {
      this.saveRelation = true
      const responses = []
      for (const question of this.listQuestions) {
        const { data } = await this.$admin.http.post(`/challenge-question/`, {
          question: question,
          challenge: this.challengeId,
          enabled: true
        }, {headers: { 'Content-Type': 'application/json' }})
        if (Object.values(data).includes('error')) {
          responses.push(question)
        }
      }

      this.message.show = true
      if (responses.length > 0) {
        this.message.text = "Error in questions" + responses.length
        this.message.type = 'error'
      } else {
        this.openRewardChallenge = false
        this.message.type = 'success'
        this.message.text = 'Reward update successfully'
        await this.questionsByChallengeAPI()
      }
      this.saveRelation = false

    },
    async questionsByChallengeAPI () {
      this.load = true
      const { data } = await this.$admin.http.get(`/challenge-question/${this.challengeId}/challenge`)
      this.questionsByChallenge = data
      this.load = false
    },
    statusSnack (value) {
      this.message.show = value.status
    },
    async allQuestions () {
      this.loadQuestionsInField = true
      const { data } =  await this.$admin.http.get(`/questions/`)
      this.questions = data
      this.loadQuestionsInField = false
    },
  },
};
</script>

<style scoped>

</style>
